import api from "../config/api";

// get all jokes
export const listJokes = () => {
  const token = localStorage.getItem("token");
  return api({
    method: "get",
    url: `/api/jokes`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// add a joke
export const createJoke = (data) => {
  const token = localStorage.getItem("token");
  return api({
    method: "post",
    url: `/api/jokes`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// get a joke
export const getJoke = (id) => {
  const token = localStorage.getItem("token");
  return api({
    method: "get",
    url: `/api/jokes/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// update a joke
export const updateJoke = ({ id, data }) => {
  const token = localStorage.getItem("token");
  return api({
    method: "put",
    url: `/api/jokes/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// delete a joke
export const deleteJoke = (id) => {
  const token = localStorage.getItem("token");
  return api({
    method: "delete",
    url: `/api/jokes/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// filter joke by category
export const filterJokeByCategory = (id) => {
  const token = localStorage.getItem("token");
  return api({
    method: "get",
    url: `/api/jokes/category/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// filter joke by type
export const filterJokeByType = (type) => {
  const token = localStorage.getItem("token");
  return api({
    method: "get",
    url: `/api/jokes/type/${type}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// joke pagination
export const jokePagination = (currentPage) => {
  const token = localStorage.getItem("token");
  return api({
    method: "get",
    url: `/api/jokes?page=${currentPage}&limit=20`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

//get upvoted by joke
export const getUpvotedByJoke = (id) => {
  // const token = localStorage.getItem("token");
  return api({
    method: "get",
    url: `/api/upvoted/${id}`,
    headers: {
      // Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};
// add upvoted
export const createUpvoted = (data) => {
  // const token = localStorage.getItem("token");
  return api({
    method: "post",
    url: `/api/upvoted`,
    headers: {
      // Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// delete upvoted
export const deleteUpvoted = (id) => {
  // const token = localStorage.getItem("token");
  return api({
    method: "delete",
    url: `/api/upvoted/${id}`,
    headers: {
      // Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

//get downvoted by joke
export const getDownvotedByJoke = (id) => {
  // const token = localStorage.getItem("token");
  return api({
    method: "get",
    url: `/api/downvoted/${id}`,
    headers: {
      // Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};
// add downvoted
export const createDownvoted = (data) => {
  // const token = localStorage.getItem("token");
  return api({
    method: "post",
    url: `/api/downvoted`,
    headers: {
      // Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// delete downvoted
export const deleteDownvoted = (id) => {
  // const token = localStorage.getItem("token");
  return api({
    method: "delete",
    url: `/api/downvoted/${id}`,
    headers: {
      // Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};
