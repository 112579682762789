import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider
} from "@mui/material";
import "../../../styles/category.css";
import { useQuery } from "react-query";
import { getCategory, deleteCategory } from "../../../services/categoryService";
import _ from "lodash";
import DeleteModal from "../../../components/DeleteModal";

const ShowMoreCategory = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState({
    open: false,
    id: null
  });
  const { data: categoryData, isLoading: getCategoryLoading } = useQuery(
    ["get-category", id],
    () => getCategory(id)
  );
  if (getCategoryLoading) {
    return "...is loading";
  }
  return (
    <>
      <div className="page-wrapper">
        <div className="page-settings">
          <div className="page-infos">
            <IconButton onClick={() => navigate("/dashboard/categories")}>
              <ArrowCircleLeftIcon
                color="primary"
                style={{ width: 34, height: 34 }}
              />
            </IconButton>
            <h5 className="page-title">Category / Show more infos</h5>
          </div>
          <div className="settings-buttons">
            <Button
              variant="outlined"
              startIcon={<EditIcon />}
              className=""
              onClick={() => navigate(`/dashboard/edit/category/${id}`)}
            >
              Edit
            </Button>

            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              className="cancel-button"
              onClick={() =>
                setShowDeleteModal({
                  open: true,
                  id: id
                })
              }
            >
              Delete
            </Button>
          </div>
        </div>
        <div className="show-more-infos-content">
          <List>
            <ListItem>
              <ListItemText primary="ID:" style={{ width: "50%" }} />
              <ListItemText
                primary={categoryData?.data.category._id}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Name:" style={{ width: "50%" }} />
              <ListItemText
                primary={categoryData?.data.category.name}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Description:" style={{ width: "50%" }} />
              <ListItemText
                primary={categoryData?.data.category.description}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Created At:" style={{ width: "50%" }} />
              <ListItemText
                primary={categoryData?.data.category.createdAt}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Updated At" style={{ width: "50%" }} />
              <ListItemText
                primary={categoryData?.data.category.updatedAt}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
          </List>
        </div>
      </div>
      {showDeleteModal.open && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          user="Category"
          queryFnt={deleteCategory}
          queryKey="list-categories"
        />
      )}
    </>
  );
};

export default ShowMoreCategory;
