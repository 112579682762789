import React, { useContext, useState, useRef, useEffect } from "react";
import "../styles/navBar.css";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import { IconButton, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../providers/Global";
import "../styles/navBar.css";
import SideBar from "./SideBar";
const NavBar = () => {
  const navigate = useNavigate();
  const [showAdminMenu, setShowAdminMenu] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const adminMenuRef = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (!adminMenuRef.current.contains(event.target)) {
        setShowAdminMenu(false);
      }
    };
    document.addEventListener("mouseover", handler);
    return () => {
      document.removeEventListener("mouseover", handler);
    };
  });
  const { currentUser, logOut } = useContext(GlobalContext);

  return (
    <>
      <div className="navbar-wrapper">
        <IconButton onClick={() => setShowSideBar(!showSideBar)}>
          <MenuIcon style={{ width: 30, height: 30 }} />
        </IconButton>
        <div className="navbar-right-side">
          <div className="admin-infos">
            <h6>{currentUser.admin.email}</h6>
            <div ref={adminMenuRef}>
              <IconButton onClick={() => setShowAdminMenu(!showAdminMenu)}>
                <MoreVertIcon style={{ height: 30 }} />
              </IconButton>
              {showAdminMenu && (
                <div className="admin-menu">
                  <MenuItem
                    style={{ paddingLeft: 20 }}
                    onClick={() => {
                      navigate("/dashboard/my-account");
                      setShowAdminMenu(false);
                    }}
                  >
                    <AccountBoxIcon style={{ marginRight: 10 }} />
                    My account
                  </MenuItem>
                  <MenuItem
                    style={{ paddingLeft: 20 }}
                    onClick={() => {
                      navigate("/dashboard/settings");
                      setShowAdminMenu(false);
                    }}
                  >
                    <SettingsApplicationsIcon style={{ marginRight: 10 }} />
                    Settings
                  </MenuItem>
                  <MenuItem style={{ paddingLeft: 20 }} onClick={logOut}>
                    <LogoutIcon style={{ marginRight: 10 }} />
                    Logout
                  </MenuItem>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showSideBar && (
        <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      )}
    </>
  );
};

export default NavBar;
