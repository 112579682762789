import React, { useState } from "react";
import { Badge, Button } from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddCommentIcon from "@mui/icons-material/AddComment";
import CommentsTable from "./components/CommentsTable";
// import AddUserModal from "./components/AddUserModal";
// import Filter from "./components/Filter";
import "../../styles/users.css";
import { useQuery } from "react-query";
import { listComments } from "../../services/commentsService";
import AddCommentModal from "./components/AddCommentModal";

const Comments = () => {
  const [showAddComment, setShowAddComment] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const {
    data: comments,
    isSuccess,
    isError,
    isLoading
  } = useQuery("list-comments", listComments);
  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID"
    },
    {
      id: "comment",
      numeric: false,
      disablePadding: true,
      label: "Comment"
    },
    {
      id: "joke",
      numeric: false,
      disablePadding: true,
      label: "Joke"
    },
    {
      id: "user",
      numeric: false,
      disablePadding: true,
      label: "User"
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: true,
      label: "Created At"
    },
    {
      id: "updatedAt",
      numeric: false,
      disablePadding: true,
      label: "Updated At"
    }
  ];
  return (
    <div className="page-wrapper">
      <div className="page-settings">
        <div className="page-infos">
          <h5 className="page-title">Comments</h5>
          <Badge badgeContent={comments?.data.totalResults} color="primary">
            <CommentIcon color="action" />
          </Badge>
        </div>
        <div className="settings-buttons">
          <Button
            variant="outlined"
            startIcon={<AddCommentIcon />}
            onClick={() => setShowAddComment(true)}
          >
            Add comment
          </Button>
          {/* <Button
            variant="contained"
            startIcon={<FilterAltIcon />}
            onClick={() => setShowFilter(true)}
          >
            Filter
          </Button> */}
        </div>
      </div>
      <CommentsTable headCells={headCells} rows={comments?.data.comments} />
      {showAddComment && (
        <AddCommentModal
          showAddComment={showAddComment}
          setShowAddComment={setShowAddComment}
        />
      )}
      {/* {showFilter && (
        <Filter showFilter={showFilter} setShowFilter={setShowFilter} />
      )} */}
    </div>
  );
};

export default Comments;
