import React, { useContext } from "react";
import { GlobalContext } from "../providers/Global";
import AxiosInterceptorsSetup from "../config/interceptor";

function AxiosInterceptorNavigate() {
  const { logOut } = useContext(GlobalContext);
  AxiosInterceptorsSetup(logOut);
  return <></>;
}

export default AxiosInterceptorNavigate;
