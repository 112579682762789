import React from "react";
import CategoryIcon from "@mui/icons-material/Category";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import PersonIcon from "@mui/icons-material/Person";
import CommentIcon from "@mui/icons-material/Comment";
import { Link } from "react-router-dom";

const SideBar = ({ showSideBar, setShowSideBar }) => {
  const sideBarData = [
    {
      title: "Users",
      path: "/dashboard/users",
      icon: <PersonIcon />
    },
    {
      title: "Jokes",
      path: "/dashboard/jokes",
      icon: <EmojiEmotionsIcon />
    },
    {
      title: "Category",
      path: "/dashboard/categories",
      icon: <CategoryIcon />
    },
    {
      title: "Comments",
      path: "/dashboard/comments",
      icon: <CommentIcon />
    }
  ];
  return (
    <>
      <div className="side-bar-wrapper">
        <div
          className="side-bar-backdrop"
          onClick={() => setShowSideBar(false)}
        />
        <nav className="side-bar">
          <ul className="side-bar-items">
            {sideBarData.map((item, i) => (
              <li
                key={i}
                className="side-bar-item"
                // onClick={() => setShowSideBar(false)}
              >
                <Link to={item.path}>
                  {item.icon} <span>{item.title}</span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default SideBar;
