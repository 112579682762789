import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider
} from "@mui/material";
import "../../../styles/jokes.css";
import { useQuery } from "react-query";
import { getJoke, deleteJoke } from "../../../services/jokeService";
import _ from "lodash";
import { Buffer } from "buffer";
import DeleteModal from "../../../components/DeleteModal";

const ShowMoreJoke = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState({
    open: false,
    id: null
  });
  const { data: jokeData, isLoading: getJokeLoading } = useQuery(
    ["get-joke", id],
    () => getJoke(id)
  );
  if (getJokeLoading) {
    return "...is loading";
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="page-settings">
          <div className="page-infos">
            <IconButton onClick={() => navigate("/dashboard/jokes")}>
              <ArrowCircleLeftIcon
                color="primary"
                style={{ width: 34, height: 34 }}
              />
            </IconButton>
            <h5 className="page-title">Joke / Show more infos</h5>
          </div>
          <div className="settings-buttons">
            <Button
              variant="outlined"
              startIcon={<EditIcon />}
              className=""
              onClick={() => navigate(`/dashboard/edit/joke/${id}`)}
            >
              Edit
            </Button>

            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              className="cancel-button"
              onClick={() =>
                setShowDeleteModal({
                  open: true,
                  id: id
                })
              }
            >
              Delete
            </Button>
          </div>
        </div>
        <div className="show-more-infos-content">
          <List>
            <ListItem>
              <ListItemText primary="ID:" style={{ width: "50%" }} />
              <ListItemText
                primary={jokeData?.data.joke._id}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Type:" style={{ width: "50%" }} />
              <ListItemText
                primary={jokeData?.data.joke.type}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            {jokeData?.data.joke.type === "TEXT" ? (
              <>
                <ListItem>
                  <ListItemText primary="Text:" style={{ width: "50%" }} />
                  <ListItemText
                    primary={jokeData?.data.joke.text}
                    style={{ width: "50%" }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Description:"
                    style={{ width: "50%" }}
                  />
                  <ListItemText
                    primary={jokeData?.data.joke.description}
                    style={{ width: "50%" }}
                  />
                </ListItem>
                <Divider />
              </>
            ) : jokeData?.data.joke.type === "VIDEO" ? (
              <>
                <ListItem>
                  <ListItemText
                    primary="Video title:"
                    style={{ width: "50%" }}
                  />
                  <ListItemText
                    primary={jokeData?.data.joke.videoTitle}
                    style={{ width: "50%" }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Video url:" style={{ width: "50%" }} />
                  <div className="show-more-video-image">
                    <video
                      alt=""
                      src={`https://nookat-new-api.yosrycoding.dev/${jokeData?.data.joke.videoUrl}`}
                      controls
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Thumb:" style={{ width: "50%" }} />
                  <div className="show-more-video-image">
                    <img
                      alt=""
                      src={`https://nookat-new-api.yosrycoding.dev/${jokeData?.data.joke.thumb}`}
                      controls
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Description:"
                    style={{ width: "50%" }}
                  />
                  <ListItemText
                    primary={jokeData?.data.joke.description}
                    style={{ width: "50%" }}
                  />
                </ListItem>
                <Divider />
              </>
            ) : jokeData?.data.joke.type === "IMAGE" ||
              jokeData?.data.joke.type === "GIF" ? (
              <>
                <ListItem>
                  <ListItemText
                    primary="Image title:"
                    style={{ width: "50%" }}
                  />
                  <ListItemText
                    primary={jokeData?.data.joke.imageTitle}
                    style={{ width: "50%" }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Image url:" style={{ width: "50%" }} />
                  <div className="show-more-video-image">
                    <img
                      alt=""
                      src={`https://nookat-new-api.yosrycoding.dev/${jokeData?.data.joke.imageUrl}`}
                      controls
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Thumb:" style={{ width: "50%" }} />
                  <ListItemText
                    primary={jokeData?.data.joke.thumb}
                    style={{ width: "50%" }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Description:"
                    style={{ width: "50%" }}
                  />
                  <ListItemText
                    primary={jokeData?.data.joke.description}
                    style={{ width: "50%" }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Source:" style={{ width: "50%" }} />
                  <ListItemText
                    primary={jokeData?.data.joke.source}
                    style={{ width: "50%" }}
                  />
                </ListItem>
                <Divider />
              </>
            ) : null}

            <ListItem>
              <ListItemText primary="Source:" style={{ width: "50%" }} />
              <ListItemText
                primary={jokeData?.data.joke.source}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Language:" style={{ width: "50%" }} />
              <ListItemText
                primary={jokeData?.data.joke.language}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="User :" style={{ width: "50%" }} />
              <ListItemText
                primary={
                  jokeData?.data.joke.user.fullName
                    ? jokeData?.data.joke.user.fullName
                    : jokeData?.data.joke.user.firstName?.concat(
                        " ",
                        jokeData?.data.joke.user.lastName
                      )
                }
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />

            <ListItem>
              <ListItemText primary="Category:" style={{ width: "50%" }} />
              <ListItemText
                primary={jokeData?.data.joke.category.name}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Upvoted:" style={{ width: "50%" }} />
              <ListItemText
                primary={jokeData?.data.joke.totalUpvoted}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Downvoted:" style={{ width: "50%" }} />
              <ListItemText
                primary={jokeData?.data.joke.totalDownvoted}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Created At:" style={{ width: "50%" }} />
              <ListItemText
                primary={jokeData?.data.joke.createdAt}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Updated At:" style={{ width: "50%" }} />
              <ListItemText
                primary={jokeData?.data.joke.updatedAt}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
          </List>
        </div>
      </div>
      {showDeleteModal.open && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          user="Joke"
          queryFnt={deleteJoke}
          queryKey="list-jokes"
        />
      )}
    </>
  );
};

export default ShowMoreJoke;
