import React, { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import AxiosInterceptorNavigate from "./components/AxiosInterceptorNavigate";
import Login from "./pages/login/Login";
import { GlobalContext } from "./providers/Global";
import PrivateRoute from "./routing/PrivateRoute";
import NewRoutes from "./routing/routes";
import { adminAccount } from "./services/authService";

function Main() {
  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = useContext(GlobalContext);
  const currentToken = localStorage.getItem("token");
  const { data: adminAccountData, isLoading: adminAccountLoading } = useQuery(
    "admin-account",
    adminAccount,
    { enabled: !currentUser && currentToken ? true : false }
  );

  useEffect(() => {
    if (adminAccountData?.success) {
      setCurrentUser(adminAccountData?.data);
      navigate("/dashboard");
    }
  }, [adminAccountData?.success]);
  return (
    <>
      {<AxiosInterceptorNavigate />}
      <Routes>
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="dashboard/*" element={<NewRoutes />} />
        </Route>
      </Routes>
    </>
  );
}

export default Main;
