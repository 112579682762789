import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField
} from "@mui/material";
import { updateAdminAccount } from "../../../services/authService";

const AdminInfos = ({ accountForm, setAccountForm, adminAccountData }) => {
  const [update, setUpdate] = useState(false);
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    setAccountForm({ ...accountForm, [e.target.name]: e.target.value });
  };

  const queryClient = useQueryClient();
  const { mutate, data: updateAdminData } = useMutation(updateAdminAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries("admin-account");
    }
  });
  const handleUpdateAdmin = () => {
    const errorsTemp = {};
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!accountForm.fullName) {
      errorsTemp.fullName = "*This field is required";
    }

    if (!pattern.test(accountForm.email)) {
      errorsTemp.email = "*Please Enter a Valid Email Address";
    }

    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      mutate(accountForm);
    }
  };
  const clearErrors = (e) => {
    setErrors({
      ...errors,
      [e.target.name]: ""
    });
  };
  useEffect(() => {
    if (updateAdminData?.success) {
      setUpdate(false);
    }
  }, [updateAdminData?.success]);

  const cancelUpdateAdmin = () => {
    setAccountForm({
      ...accountForm,
      fullName: adminAccountData?.data.admin.fullName,
      email: adminAccountData?.data.admin.email
    });
    setUpdate(false);
  };
  return (
    <div className="show-more-infos-content" style={{ marginBottom: 40 }}>
      <h3 className="account-detail-title">My infos</h3>
      <List>
        <ListItem>
          <ListItemText primary="Full Name:" style={{ width: "50%" }} />
          {update ? (
            <div style={{ width: "50%" }}>
              <TextField
                name="fullName"
                id="outlined-basic"
                label="Full name"
                variant="outlined"
                value={accountForm.fullName}
                onChange={handleChange}
                style={{ width: "100%" }}
                error={errors.fullName ? true : false}
                onFocus={clearErrors}
              />
              {errors.fullName && (
                <p className="add-joke-error-msg">{errors.fullName}</p>
              )}
            </div>
          ) : (
            <ListItemText
              primary={adminAccountData?.data.admin.fullName}
              style={{ width: "50%" }}
            />
          )}
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemText primary="Email:" style={{ width: "50%" }} />
          {update ? (
            <div style={{ width: "50%" }}>
              <TextField
                name="email"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                type="email"
                value={accountForm.email}
                onChange={handleChange}
                style={{ width: "100%" }}
                error={errors.email ? true : false}
                onFocus={clearErrors}
              />
              {errors.email && (
                <p className="add-joke-error-msg">{errors.email}</p>
              )}
            </div>
          ) : (
            <ListItemText
              primary={adminAccountData?.data.admin.email}
              style={{ width: "50%" }}
            />
          )}
        </ListItem>
        <Divider />
        <ListItem style={{ justifyContent: "flex-end", gap: 20 }}>
          {update ? (
            <>
              <Button
                variant="outlined"
                className="cancel-update-button"
                onClick={cancelUpdateAdmin}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                className="save-update-button"
                onClick={handleUpdateAdmin}
              >
                Save
              </Button>
            </>
          ) : (
            <Button
              variant="outlined"
              className="save-update-button"
              onClick={() => setUpdate(true)}
            >
              Edit my infos
            </Button>
          )}
        </ListItem>
      </List>
    </div>
  );
};

export default AdminInfos;
