import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Alert,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment
} from "@mui/material";
import { useMutation } from "react-query";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { updatePassword } from "../../../services/authService";

const UpdatePassword = () => {
  const [passwordForm, setPasswordForm] = useState({
    oldPassword: "",
    newPassword: ""
  });
  const [errors, setErrors] = useState({});
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const handleChangePassword = (e) => {
    setPasswordForm({ ...passwordForm, [e.target.name]: e.target.value });
  };

  const { mutate: mutatePassword, data: updatePasswordData } =
    useMutation(updatePassword);

  const handleUpdatePassword = () => {
    const errorsTemp = {};

    if (!passwordForm.oldPassword) {
      errorsTemp.oldPassword = "*Please enter your current password";
    }

    if (passwordForm.newPassword.length < 6) {
      errorsTemp.newPassword = "*Password must be at least 6 characters";
    }

    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      mutatePassword(passwordForm);
    }
  };
  const clearErrors = (e) => {
    setErrors({
      ...errors,
      [e.target.name]: ""
    });
  };
  useEffect(() => {
    if (updatePasswordData?.success) {
      setShowAlert(true);
    }
    return () => {
      setShowAlert(false);
    };
  }, [updatePasswordData?.success]);

  return (
    <div className="show-more-infos-content">
      <h3 className="account-detail-title">Update my password</h3>
      <List>
        <ListItem>
          <ListItemText primary="Old Password:" style={{ width: "50%" }} />
          <div style={{ width: "50%" }}>
            <FormControl
              sx={{ m: 1, width: "100%", margin: 0 }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Old Password
              </InputLabel>
              <OutlinedInput
                name="oldPassword"
                id="outlined-adornment-password"
                type={showOldPassword ? "text" : "password"}
                value={passwordForm.oldPassword}
                onChange={handleChangePassword}
                error={errors.oldPassword ? true : false}
                onFocus={clearErrors}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(e) => setShowOldPassword(!showOldPassword)}
                    >
                      {showOldPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Old Password"
              />
            </FormControl>
            {errors.oldPassword && (
              <p className="add-joke-error-msg">
                *Please enter your current password
              </p>
            )}
          </div>
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemText primary="New Password:" style={{ width: "50%" }} />
          <div style={{ width: "50%" }}>
            <FormControl
              sx={{ m: 1, width: "100%", margin: 0 }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                New Password
              </InputLabel>
              <OutlinedInput
                name="newPassword"
                id="outlined-adornment-password"
                type={showNewPassword ? "text" : "password"}
                value={passwordForm.newPassword}
                onChange={handleChangePassword}
                error={errors.newPassword ? true : false}
                onFocus={clearErrors}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(e) => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
            </FormControl>
            {errors.newPassword && (
              <p className="add-joke-error-msg">{errors.newPassword}</p>
            )}
          </div>
        </ListItem>
        <Divider />
        <ListItem style={{ justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            className="save-update-button"
            onClick={handleUpdatePassword}
          >
            Update my password
          </Button>
        </ListItem>
      </List>
      {showAlert && (
        <>
          <Alert
            severity="success"
            onClose={() => {
              setShowAlert(false);
            }}
          >
            Password is updated!
          </Alert>
        </>
      )}
    </div>
  );
};

export default UpdatePassword;
