import api from "../config/api";

// get all comments
export const listComments = () => {
  const token = localStorage.getItem("token");
  return api({
    method: "get",
    url: `/api/comments`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// create comment
export const createComment = (data) => {
  const token = localStorage.getItem("token");
  return api({
    method: "post",
    url: `/api/comments`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// get a comment
export const getComment = (id) => {
  const token = localStorage.getItem("token");
  return api({
    method: "get",
    url: `/api/comments/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// update a comment
export const updateComment = ({ id, data }) => {
  const token = localStorage.getItem("token");
  return api({
    method: "put",
    url: `/api/comments/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// delete a comment
export const deleteComment = (id) => {
  const token = localStorage.getItem("token");
  return api({
    method: "delete",
    url: `/api/comments/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};
