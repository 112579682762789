import api from "../config/api";

// get all users
export const listUsers = () => {
  const token = localStorage.getItem("token");
  return api({
    method: "get",
    url: `/api/users`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// create user
export const createUser = (data) => {
  const token = localStorage.getItem("token");
  return api({
    method: "post",
    url: `/api/user`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// get a user
export const getUser = (id) => {
  const token = localStorage.getItem("token");
  return api({
    method: "get",
    url: `/api/users/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// update a user
export const updateUser = ({ id, data }) => {
  const token = localStorage.getItem("token");
  return api({
    method: "put",
    url: `/api/users/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// delete a user
export const deleteUser = (id) => {
  const token = localStorage.getItem("token");
  return api({
    method: "delete",
    url: `/api/users/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};
