import React, { useState } from "react";
import { Badge, Button } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import UserTable from "./components/UserTable";
import AddUserModal from "./components/AddUserModal";
import Filter from "./components/Filter";
import "../../styles/users.css";
import { useQuery } from "react-query";
import { listUsers } from "../../services/usersService";

const Users = () => {
  const [showAddUser, setShowAddUser] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const {
    data: users,
    isSuccess,
    isError,
    isLoading
  } = useQuery("list-users", listUsers);
  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID"
    },
    {
      id: "firstName",
      numeric: false,
      disablePadding: true,
      label: "First Name"
    },
    {
      id: "lastName",
      numeric: false,
      disablePadding: true,
      label: "Last Name"
    },
    {
      id: "email",
      numeric: false,
      disablePadding: true,
      label: "Email"
    },
    // {
    //   id: "password",
    //   numeric: false,
    //   disablePadding: true,
    //   label: "Password"
    // },
    {
      id: "deviceType",
      numeric: false,
      disablePadding: true,
      label: "Device Type"
    },
    {
      id: "role",
      numeric: false,
      disablePadding: true,
      label: "Role"
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: true,
      label: "Created At"
    },
    {
      id: "modifiedAt",
      numeric: false,
      disablePadding: true,
      label: "Modified At"
    }
  ];

  return (
    <div className="page-wrapper">
      <div className="page-settings">
        <div className="page-infos">
          <h5 className="page-title">Users</h5>
          <Badge badgeContent={users?.data.users.length} color="primary">
            <GroupIcon color="action" />
          </Badge>
        </div>
        {/* <div className="settings-buttons">
          <Button
            variant="outlined"
            startIcon={<PersonAddIcon />}
            onClick={() => setShowAddUser(true)}
          >
            Add User
          </Button>
          <Button
            variant="contained"
            startIcon={<FilterAltIcon />}
            onClick={() => setShowFilter(true)}
          >
            Filter
          </Button>
        </div> */}
      </div>
      <UserTable headCells={headCells} rows={users?.data.users} />
      {showAddUser && (
        <AddUserModal
          showAddUser={showAddUser}
          setShowAddUser={setShowAddUser}
        />
      )}
      {showFilter && (
        <Filter showFilter={showFilter} setShowFilter={setShowFilter} />
      )}
    </div>
  );
};

export default Users;
