import React, { useState } from "react";
import {
  Modal,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { createUser } from "../../../services/usersService";

const AddUserModal = ({ showAddUser, setShowAddUser }) => {
  const [userForm, setUserForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    deviceType: "",
    role: ""
  });
  const handleChange = (e) => {
    setUserForm({ ...userForm, [e.target.name]: e.target.value });
  };
  const handleClose = () => {
    setShowAddUser(false);
  };
  const cancelAddUser = () => {
    setUserForm({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      deviceType: "",
      role: ""
    });
    handleClose();
  };
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isError } = useMutation(createUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-users");
      handleClose();
    }
  });
  const handleSubmitUser = () => {
    mutate(userForm);
  };
  return (
    <Modal open={showAddUser} onClose={handleClose}>
      <Grid container item xs={7} direction="column" className="add-user-modal">
        <h1 className="modal-title">Add User</h1>
        <div className="modal-content">
          <div>
            <TextField
              name="firstName"
              id="outlined-basic"
              label="First Name"
              variant="outlined"
              value={userForm.firstName}
              onChange={handleChange}
              style={{ width: "50%" }}
            />
            <TextField
              name="lastName"
              id="outlined-basic"
              label="Last Name"
              variant="outlined"
              value={userForm.lastName}
              onChange={handleChange}
              style={{ width: "50%" }}
            />
          </div>
          <div>
            <TextField
              name="email"
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={userForm.email}
              onChange={handleChange}
              style={{ width: "50%" }}
            />
            <TextField
              name="password"
              id="outlined-basic"
              label="Password"
              variant="outlined"
              value={userForm.password}
              onChange={handleChange}
              style={{ width: "50%" }}
            />
          </div>
          <div>
            <TextField
              name="deviceType"
              id="outlined-basic"
              label="Device Type"
              variant="outlined"
              value={userForm.deviceType}
              onChange={handleChange}
              style={{ width: "50%" }}
            />
            <FormControl style={{ width: "50%" }}>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                name="role"
                label="Role"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userForm.role}
                onChange={handleChange}
              >
                <MenuItem value="ADMIN">Admin</MenuItem>
                <MenuItem value="USER">User</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="modal-buttons">
          <Button
            variant="outlined"
            className="add-button"
            onClick={handleSubmitUser}
          >
            Add
          </Button>
          <Button
            variant="outlined"
            className="cancel-button"
            onClick={cancelAddUser}
          >
            Cancel
          </Button>
        </div>
      </Grid>
    </Modal>
  );
};

export default AddUserModal;
