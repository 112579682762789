import React, { useState } from "react";
import { useQuery } from "react-query";
import { Button, IconButton } from "@mui/material";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AddAdminModal from "./settingsComponents/AddAdminModal";
import { listAdmins, deleteAdmin } from "../../services/authService";
import DeleteModal from "../../components/DeleteModal";
import ShowInfosAdminModal from "./settingsComponents/ShowInfosAdminModal";

const Settings = () => {
  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const [showInfosModal, setShowInfosModal] = useState({
    open: false,
    id: null
  });
  const [showDeleteModal, setShowDeleteModal] = useState({
    open: false,
    id: null
  });

  const { data: admins, isLoading } = useQuery("list-admins", listAdmins);

  if (isLoading) {
    return "...is loading";
  }
  return (
    <div className="page-wrapper">
      <div className="page-settings">
        <div className="page-infos">
          <IconButton>
            <SettingsApplicationsIcon
              style={{ width: 34, height: 34, color: "#f6416c" }}
            />
          </IconButton>
          <h5 className="page-title">Settings</h5>
        </div>
      </div>
      <div className="admins-container">
        <div className="add-admin-container">
          <div className="inner-add-admin-container">
            <IconButton onClick={() => setShowAddAdmin(true)}>
              <AddCircleIcon
                color="primary"
                style={{ width: 35, height: 35 }}
              />
            </IconButton>
          </div>

          <p>Add an admin</p>
        </div>
        {admins?.data.admins.map((el, i) => (
          <div className="admin-container" key={i}>
            <h3>{el.fullName}</h3>

            <Button
              variant="outlined"
              className="save-update-button"
              onClick={() =>
                setShowInfosModal({
                  open: true,
                  id: el._id
                })
              }
            >
              Show infos
            </Button>

            <IconButton
              className="delete-admin"
              onClick={() =>
                setShowDeleteModal({
                  open: true,
                  id: el._id
                })
              }
            >
              <CancelIcon
                style={{
                  color: "#f6416c",
                  background: "#fff",
                  borderRadius: "50%"
                }}
              />
            </IconButton>
          </div>
        ))}
      </div>
      {showAddAdmin && (
        <AddAdminModal
          showAddAdmin={showAddAdmin}
          setShowAddAdmin={setShowAddAdmin}
        />
      )}
      {showInfosModal.open && (
        <ShowInfosAdminModal
          showInfosModal={showInfosModal}
          setShowInfosModal={setShowInfosModal}
        />
      )}
      {showDeleteModal.open && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          item="Admin"
          queryFnt={deleteAdmin}
          queryKey="list-admins"
        />
      )}
    </div>
  );
};

export default Settings;
