import React, { useState } from "react";
import { Modal, Grid, TextField, Button } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { createCategory } from "../../../services/categoryService";

const AddCategoryModal = ({ showAddCategory, setShowAddCategory }) => {
  const [categoryForm, setCategoryForm] = useState({
    name: "",
    description: ""
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    setCategoryForm({ ...categoryForm, [e.target.name]: e.target.value });
  };
  const handleClose = () => {
    setShowAddCategory(false);
  };
  const cancelAddCategory = () => {
    setCategoryForm({
      name: "",
      description: ""
    });
    handleClose();
  };
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isError } = useMutation(createCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-categories");
      handleClose();
    }
  });
  const handleSubmitCategory = () => {
    const errorsTemp = {};

    if (!categoryForm.name) {
      errorsTemp.name = "*This field is required";
    }
    if (!categoryForm.description) {
      errorsTemp.description = "*This field is required";
    }
    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      mutate(categoryForm);
    }
  };
  const clearErrors = (e) => {
    setErrors({
      ...errors,
      [e.target.name]: ""
    });
  };
  return (
    <Modal open={showAddCategory} onClose={handleClose}>
      <Grid
        container
        item
        xs={5}
        direction="column"
        className="add-category-modal"
      >
        <h1 className="modal-title">Add Category</h1>
        <div className="category-modal-content">
          <div>
            <TextField
              name="name"
              id="outlined-basic"
              label="Name"
              variant="outlined"
              value={categoryForm.name}
              onChange={handleChange}
              style={{ width: "100%" }}
              error={errors.name ? true : false}
              onFocus={clearErrors}
            />
            {errors.name && (
              <p className="add-category-error-msg">{errors.name}</p>
            )}
            <TextField
              name="description"
              id="outlined-basic"
              label="Description"
              variant="outlined"
              value={categoryForm.description}
              onChange={handleChange}
              style={{ width: "100%" }}
              multiline
              rows={2}
              error={errors.description ? true : false}
              onFocus={clearErrors}
            />
            {errors.description && (
              <p className="add-category-error-msg">{errors.description}</p>
            )}
          </div>
        </div>
        <div className="modal-buttons">
          <Button
            variant="outlined"
            className="add-button"
            onClick={handleSubmitCategory}
          >
            Add
          </Button>
          <Button
            variant="outlined"
            className="cancel-button"
            onClick={cancelAddCategory}
          >
            Cancel
          </Button>
        </div>
      </Grid>
    </Modal>
  );
};

export default AddCategoryModal;
