import React, { useState } from "react";
import {
  Modal,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { listUsers } from "../../../services/usersService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createComment } from "../../../services/commentsService";

const AddCommentModal = ({ showAddComment, setShowAddComment }) => {
  const [commentForm, setCommentForm] = useState({
    comment: "",
    joke: "",
    user: ""
  });
  const handleChange = (e) => {
    setCommentForm({ ...commentForm, [e.target.name]: e.target.value });
  };
  const handleClose = () => {
    setShowAddComment(false);
  };
  const {
    data: users,
    isSuccess: isSuccess_users,
    isError: isError_users,
    isLoading: isLoading_users
  } = useQuery("list-users", listUsers);
  const cancelAddComment = () => {
    setCommentForm({
      comment: "",
      joke: "",
      user: ""
    });
    handleClose();
  };
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isError } = useMutation(createComment, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-comments");
      handleClose();
    }
  });
  const handleSubmitComment = () => {
    mutate(commentForm);
  };
  if (isLoading_users) {
    return "...is loading";
  }
  return (
    <Modal open={showAddComment} onClose={handleClose}>
      <Grid container item xs={6} direction="column" className="add-joke-modal">
        <h1 className="modal-title">Add Comment</h1>
        <div className="joke-modal-content">
          <Grid container item md={12} xs={12} style={{ marginBottom: 10 }}>
            <TextField
              name="comment"
              id="outlined-basic"
              label="Comment"
              variant="outlined"
              value={commentForm.comment}
              onChange={handleChange}
              style={{ width: "100%" }}
            />
          </Grid>

          <Grid container item md={12} xs={12} style={{ marginBottom: 10 }}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">User</InputLabel>
              <Select
                name="user"
                label="User"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={commentForm.user}
                onChange={handleChange}
              >
                {users?.data.users.map((el, i) => (
                  <MenuItem key={i} value={el._id}>
                    {el.firstName?.concat(" ", el.lastName)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid container item md={12} xs={12}>
            <TextField
              name="joke"
              id="outlined-basic"
              label="Joke id"
              variant="outlined"
              value={commentForm.joke}
              onChange={handleChange}
              style={{ width: "100%" }}
            />
          </Grid>
        </div>
        <div className="modal-buttons">
          <Button
            variant="outlined"
            className="add-button"
            onClick={handleSubmitComment}
          >
            Add
          </Button>
          <Button
            variant="outlined"
            className="cancel-button"
            onClick={cancelAddComment}
          >
            Cancel
          </Button>
        </div>
      </Grid>
    </Modal>
  );
};

export default AddCommentModal;
