import React, { useState } from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  IconButton,
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { useQuery } from "react-query";
import { listCategories } from "../../../services/categoryService";

const FilterJoke = ({
  showFilter,
  setShowFilter,
  categoryId,
  setCategoryId,
  refetchByCategory,
  jokeType,
  setJokeType,
  refetchByType
}) => {
  const {
    data: categories,
    isSuccess: isSuccess_categories,
    isError: isError_categories,
    isLoading: isLoading_categories
  } = useQuery("list-categories", listCategories);

  const handleChangeType = (e) => {
    setJokeType(e.target.value);
  };
  const handleResetType = () => {
    setJokeType("");
  };
  const handleChangeCategory = (e) => {
    setCategoryId(e.target.value);
  };
  const handleResetCategory = () => {
    setCategoryId("");
  };
  if (isLoading_categories) {
    return "...is loading";
  }
  return (
    <>
      <div className="filter-wrapper">
        <div className="filter-backdrop" onClick={() => setShowFilter(false)} />
        <div
          className={showFilter ? "filter-content active" : "filter-content"}
        >
          <div className="filter-content-header">
            <IconButton onClick={() => setShowFilter(false)}>
              <ArrowCircleLeftIcon
                color="primary"
                style={{ width: 34, height: 34 }}
              />
            </IconButton>
            <h1>Filters</h1>
          </div>
          <div className="filter-inputs">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Filter by type</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Select type joke</Typography>
                <FormControl style={{ width: "100%", marginTop: 20 }}>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    name="type"
                    label="Type"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={jokeType}
                    onChange={handleChangeType}
                  >
                    <MenuItem value="TEXT">Text</MenuItem>
                    <MenuItem value="IMAGE">Image</MenuItem>
                    <MenuItem value="VIDEO">Video</MenuItem>
                    <MenuItem value="GIF">GIF</MenuItem>
                  </Select>
                </FormControl>
              </AccordionDetails>
              <div className="filter-buttons-container">
                <Button
                  variant="outlined"
                  className="filter-button"
                  onClick={refetchByType}
                >
                  Apply changes
                </Button>
                <Button
                  variant="outlined"
                  className="reset-button"
                  onClick={handleResetType}
                >
                  Reset
                </Button>
              </div>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Filter by category</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Select category joke</Typography>
                <FormControl style={{ width: "100%", marginTop: 20 }}>
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                  <Select
                    name="categoryId"
                    label="Category"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={categoryId}
                    onChange={handleChangeCategory}
                  >
                    {categories?.data.categories.map((el, i) => (
                      <MenuItem key={i} value={el._id}>
                        {el.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </AccordionDetails>
              <div className="filter-buttons-container">
                <Button
                  variant="outlined"
                  className="filter-button"
                  onClick={refetchByCategory}
                >
                  Apply changes
                </Button>
                <Button
                  variant="outlined"
                  className="reset-button"
                  onClick={handleResetCategory}
                >
                  Reset
                </Button>
              </div>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterJoke;
