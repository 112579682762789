import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Alert
} from "@mui/material";
import "../../../styles/category.css";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getCategory,
  updateCategory,
  deleteCategory
} from "../../../services/categoryService";
import _ from "lodash";
import DeleteModal from "../../../components/DeleteModal";

const EditCategory = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [categoryForm, setCategoryForm] = useState(null);
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState({
    open: false,
    id: null
  });
  const handleChange = (e) => {
    setCategoryForm({ ...categoryForm, [e.target.name]: e.target.value });
  };
  const { data: categoryData, isLoading: getCategoryLoading } = useQuery(
    ["get-category", id],
    () => getCategory(id)
  );
  useEffect(() => {
    setCategoryForm({ ...categoryData?.data.category });
  }, [categoryData?.success]);

  const queryClient = useQueryClient();
  const {
    mutate,
    data: updateCategoryData,
    isSuccess,
    isError
  } = useMutation(updateCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-categories");
    }
  });

  const handleEditCategory = () => {
    const dataToSend = _.omit(categoryForm, [
      "_id",
      "createdAt",
      "updatedAt",
      "isDeleted"
    ]);
    const errorsTemp = {};

    if (!categoryForm.name) {
      errorsTemp.name = "*This field is required";
    }
    if (!categoryForm.description) {
      errorsTemp.description = "*This field is required";
    }
    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      mutate({ id: categoryForm._id, data: dataToSend });
    }
  };
  const clearErrors = (e) => {
    setErrors({
      ...errors,
      [e.target.name]: ""
    });
  };
  useEffect(() => {
    if (updateCategoryData?.success) {
      setShowAlert(true);
    }
    return () => {
      setShowAlert(false);
    };
  }, [updateCategoryData?.success]);

  if (getCategoryLoading) {
    return "...is loading";
  }
  return (
    <>
      {categoryForm && (
        <div className="page-wrapper">
          <div className="page-settings">
            <div className="page-infos">
              <IconButton onClick={() => navigate("/dashboard/categories")}>
                <ArrowCircleLeftIcon
                  color="primary"
                  style={{ width: 34, height: 34 }}
                />
              </IconButton>
              <h5 className="page-title">Category / Edit</h5>
            </div>
            <div className="settings-buttons">
              <Button
                variant="outlined"
                startIcon={<InfoIcon />}
                className=""
                onClick={() => navigate(`/dashboard/show/category/${id}`)}
              >
                Show more
              </Button>

              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                className="cancel-button"
                onClick={() =>
                  setShowDeleteModal({
                    open: true,
                    id: id
                  })
                }
              >
                Delete
              </Button>
            </div>
          </div>
          <div className="show-more-infos-content">
            <List>
              <ListItem>
                <ListItemText primary="Name:" />
                <div style={{ width: "50%" }}>
                  <TextField
                    name="name"
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    value={categoryForm.name}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    error={errors.name ? true : false}
                    onFocus={clearErrors}
                  />
                  {errors.name && (
                    <p
                      className="add-category-error-msg"
                      style={{ marginTop: 10 }}
                    >
                      {errors.name}
                    </p>
                  )}
                </div>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="Description:" />
                <div style={{ width: "50%" }}>
                  <TextField
                    name="description"
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    value={categoryForm.description}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    multiline
                    rows={2}
                    error={errors.description ? true : false}
                    onFocus={clearErrors}
                  />
                  {errors.description && (
                    <p
                      className="add-category-error-msg"
                      style={{ marginTop: 10 }}
                    >
                      {errors.description}
                    </p>
                  )}
                </div>
              </ListItem>
              <Divider />
              <ListItem style={{ justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  className=""
                  onClick={handleEditCategory}
                >
                  Edit category infos
                </Button>
              </ListItem>
              <Divider />
            </List>
            {showAlert && (
              <>
                <Alert
                  severity="success"
                  onClose={() => {
                    setShowAlert(false);
                  }}
                >
                  Category is updated!
                </Alert>
              </>
            )}
          </div>
        </div>
      )}
      {showDeleteModal.open && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          user="Category"
          queryFnt={deleteCategory}
          queryKey="list-categories"
        />
      )}
    </>
  );
};

export default EditCategory;
