import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Modal,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { createJoke } from "../../../services/jokeService";
import { listCategories } from "../../../services/categoryService";
import { GlobalContext } from "../../../providers/Global";

const AddJokeModal = ({ showAddJoke, setShowAddJoke }) => {
  const [showJokeImage, setShowJokeImage] = useState(null);
  const [showJokeVideo, setShowJokeVideo] = useState(null);
  const addJokeImage = useRef();
  const addJokeVideo = useRef();
  const { currentUser } = useContext(GlobalContext);
  const {
    data: categories,
    isSuccess: isSuccess_categories,
    isError: isError_categories,
    isLoading: isLoading_categories
  } = useQuery("list-categories", listCategories);
  const [jokeForm, setJokeForm] = useState({
    text: "",
    file: null,
    imageUrl: "",
    imageTitle: "",
    videoUrl: "",
    videoTitle: "",
    description: "",
    type: "",
    source: "",
    category: "",
    user: currentUser.admin._id
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    setJokeForm({ ...jokeForm, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    setJokeForm({
      ...jokeForm,
      file: null,
      text: "",
      imageUrl: "",
      imageTitle: "",
      videoUrl: "",
      videoTitle: "",
      description: "",
      source: "",
      category: ""
    });
    setShowJokeImage(null);
    setShowJokeVideo(null);
    setErrors({});
  }, [jokeForm.type]);

  const handleAddJokeImage = (e) => {
    if (e.target.files[0].size > 2000000) {
      let errorsTemp = {};
      errorsTemp.file = "*This field size must be less then 2mo";
      setErrors(errorsTemp);
    } else {
      setJokeForm({ ...jokeForm, file: e.target.files[0] });
      console.log(jokeForm.file);
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setShowJokeImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const handleAddJokeVideo = (e) => {
    if (e.target.files[0].size > 20000000) {
      let errorsTemp = {};
      errorsTemp.file = "*This field size must be less then 20mo";
      setErrors(errorsTemp);
    } else {
      setJokeForm({ ...jokeForm, file: e.target.files[0] });
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setShowJokeVideo(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleClose = () => {
    setShowAddJoke(false);
  };
  const cancelAddJoke = () => {
    setJokeForm({
      text: "",
      file: null,
      imageUrl: "",
      imageTitle: "",
      videoUrl: "",
      videoTitle: "",
      description: "",
      type: "",
      source: "",
      category: "",
      user: currentUser.admin._id
    });
    handleClose();
  };
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isError } = useMutation(createJoke, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-jokes");
      handleClose();
    }
  });
  const handleSubmitJoke = () => {
    const errorsTemp = {};
    if (!jokeForm.type) {
      errorsTemp.type = "*This field is required";
    }
    if (!jokeForm.category) {
      errorsTemp.category = "*This field is required";
    }
    if (jokeForm.type === "TEXT") {
      if (!jokeForm.text) {
        errorsTemp.text = "*This field is required";
      }
    }
    if (
      jokeForm.type === "IMAGE" ||
      jokeForm.type === "GIF" ||
      jokeForm.type === "VIDEO"
    ) {
      if (!jokeForm.file) {
        errorsTemp.file = "*This field is required";
      }
      if (!jokeForm.source) {
        errorsTemp.source = "*This field is required";
      }
    }

    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      const formData = new FormData();
      for (var key in jokeForm) {
        if (Boolean(jokeForm[key])) {
          formData.append(key, jokeForm[key]);
        }
      }
      mutate(formData);
    }
  };
  const clearErrors = (e) => {
    setErrors({
      ...errors,
      [e.target.name]: ""
    });
  };
  if (isLoading_categories) {
    return "...is loading";
  }
  return (
    <Modal open={showAddJoke} onClose={handleClose}>
      <div className="add-joke-modal">
        <h1 className="modal-title">Add Joke</h1>
        <div className="joke-modal-content">
          <Grid container item md={12} xs={12} style={{ marginBottom: 10 }}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                name="type"
                label="Type"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={jokeForm.type}
                onChange={handleChange}
                error={errors.type ? true : false}
                onOpen={() => setErrors({ ...errors, type: "" })}
              >
                <MenuItem value="TEXT">Text</MenuItem>
                <MenuItem value="IMAGE">Image</MenuItem>
                <MenuItem value="VIDEO">Video</MenuItem>
                <MenuItem value="GIF">GIF</MenuItem>
              </Select>
            </FormControl>
            {errors.type && <p className="add-joke-error-msg">{errors.type}</p>}
          </Grid>

          <Grid
            container
            item
            md={12}
            xs={12}
            style={{ gap: 8, marginBottom: 10 }}
          >
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                name="category"
                label="Category"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={jokeForm.category}
                onChange={handleChange}
                error={errors.category ? true : false}
                onOpen={() => setErrors({ ...errors, category: "" })}
              >
                {categories?.data.categories.map((el, i) => (
                  <MenuItem key={i} value={el._id}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors.category && (
              <p className="add-joke-error-msg">{errors.category}</p>
            )}
          </Grid>
          {jokeForm.type === "TEXT" ? (
            <>
              <Grid container item md={12} xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  name="text"
                  id="outlined-basic"
                  label="Text"
                  variant="outlined"
                  value={jokeForm.text}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  multiline
                  rows={4}
                  error={errors.text ? true : false}
                  onFocus={clearErrors}
                />
                {errors.text && (
                  <p className="add-joke-error-msg">{errors.text}</p>
                )}
              </Grid>
              <Grid container item md={12} xs={12}>
                <TextField
                  name="description"
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  value={jokeForm.description}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  multiline
                  rows={2}
                />
              </Grid>
            </>
          ) : jokeForm.type === "VIDEO" ? (
            <>
              <Grid
                container
                item
                md={12}
                xs={12}
                style={{ marginBottom: 10, gap: 8 }}
              >
                <TextField
                  name="videoTitle"
                  id="outlined-basic"
                  label="Video Title"
                  variant="outlined"
                  value={jokeForm.videoTitle}
                  onChange={handleChange}
                  style={{ width: "49%" }}
                />
                <div style={{ width: "49%" }}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Source
                    </InputLabel>
                    <Select
                      name="source"
                      label="Source"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={jokeForm.source}
                      onChange={handleChange}
                      error={errors.source ? true : false}
                      onOpen={() => setErrors({ ...errors, source: "" })}
                    >
                      <MenuItem value="LOCAL">Local</MenuItem>
                    </Select>
                  </FormControl>
                  {errors.source && (
                    <p className="add-joke-error-msg">{errors.source}</p>
                  )}
                </div>
              </Grid>
              <Grid
                container
                item
                md={12}
                xs={12}
                style={{ marginBottom: 10, gap: 8 }}
              >
                <div style={{ width: "49%" }}>
                  <TextField
                    name="description"
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    value={jokeForm.description}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    multiline
                    rows={6}
                  />
                </div>
                <div style={{ width: "49%" }}>
                  {showJokeVideo ? (
                    <div className="show-joke-image">
                      <video alt="" src={showJokeVideo} controls />
                      <IconButton
                        className="add-joke-image-bttn"
                        onClick={() => addJokeVideo.current.click()}
                      >
                        <VideoFileIcon color="primary" />
                      </IconButton>
                      <label
                        ref={addJokeVideo}
                        htmlFor="importVideoJoke"
                      ></label>
                      <input
                        type="file"
                        id="importVideoJoke"
                        onChange={handleAddJokeVideo}
                        accept=".mp4, .3gp, .mov"
                      />
                    </div>
                  ) : (
                    <>
                      <div
                        className={
                          errors.file
                            ? "add-joke-image error"
                            : "add-joke-image"
                        }
                      >
                        <IconButton
                          onClick={() => addJokeVideo.current.click()}
                        >
                          <VideoFileIcon style={{ width: 40, height: 40 }} />
                        </IconButton>
                        <p>add joke video</p>

                        <label
                          ref={addJokeVideo}
                          htmlFor="importVideoJoke"
                        ></label>
                        <input
                          type="file"
                          id="importVideoJoke"
                          onChange={handleAddJokeVideo}
                          accept=".mp4, .3gp, .mov"
                        />
                      </div>
                      {errors.file && (
                        <p className="add-joke-error-msg">{errors.file}</p>
                      )}
                    </>
                  )}
                </div>
              </Grid>
            </>
          ) : jokeForm.type === "IMAGE" || jokeForm.type === "GIF" ? (
            <>
              <Grid
                container
                item
                md={12}
                xs={12}
                style={{ gap: 8, marginBottom: 10 }}
              >
                <TextField
                  name="imageTitle"
                  id="outlined-basic"
                  label="Image Title"
                  variant="outlined"
                  value={jokeForm.imageTitle}
                  onChange={handleChange}
                  style={{ width: "49%" }}
                />

                <div style={{ width: "49%" }}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Source
                    </InputLabel>
                    <Select
                      name="source"
                      label="Source"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={jokeForm.source}
                      onChange={handleChange}
                      error={errors.source ? true : false}
                      onOpen={() => setErrors({ ...errors, source: "" })}
                    >
                      <MenuItem value="LOCAL">Local</MenuItem>
                    </Select>
                  </FormControl>
                  {errors.source && (
                    <p className="add-joke-error-msg">{errors.source}</p>
                  )}
                </div>
              </Grid>
              <Grid
                container
                item
                md={12}
                xs={12}
                style={{ marginBottom: 10, gap: 8 }}
              >
                <div style={{ width: "49%" }}>
                  <TextField
                    name="description"
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    value={jokeForm.description}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    multiline
                    rows={6}
                  />
                </div>
                <div style={{ width: "49%" }}>
                  {showJokeImage ? (
                    <div className="show-joke-image">
                      <img alt="" src={showJokeImage} />
                      <IconButton
                        className="add-joke-image-bttn"
                        onClick={() => addJokeImage.current.click()}
                      >
                        <AddPhotoAlternateIcon color="primary" />
                      </IconButton>
                      <label
                        ref={addJokeImage}
                        htmlFor="importImageJoke"
                      ></label>
                      <input
                        type="file"
                        id="importImageJoke"
                        onChange={handleAddJokeImage}
                        accept={
                          jokeForm.type === "IMAGE"
                            ? ".png, .jpg, .jpeg"
                            : ".gif"
                        }
                      />
                    </div>
                  ) : (
                    <>
                      <div
                        className={
                          errors.file
                            ? "add-joke-image error"
                            : "add-joke-image"
                        }
                      >
                        <IconButton
                          onClick={() => addJokeImage.current.click()}
                        >
                          <AddPhotoAlternateIcon
                            style={{ width: 40, height: 40 }}
                          />
                        </IconButton>
                        <p>add joke image</p>

                        <label
                          ref={addJokeImage}
                          htmlFor="importImageJoke"
                        ></label>
                        <input
                          type="file"
                          id="importImageJoke"
                          onChange={handleAddJokeImage}
                          accept={
                            jokeForm.type === "IMAGE"
                              ? ".png, .jpg, .jpeg"
                              : ".gif"
                          }
                        />
                      </div>
                      {errors.file && (
                        <p className="add-joke-error-msg">{errors.file}</p>
                      )}
                    </>
                  )}
                </div>
              </Grid>
            </>
          ) : null}
        </div>
        <div className="modal-buttons">
          <Button
            variant="outlined"
            className="add-button"
            onClick={handleSubmitJoke}
          >
            Add
          </Button>
          <Button
            variant="outlined"
            className="cancel-button"
            onClick={cancelAddJoke}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddJokeModal;
