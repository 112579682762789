import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Alert
} from "@mui/material";
import "../../../styles/users.css";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getUser,
  updateUser,
  deleteUser
} from "../../../services/usersService";
import _ from "lodash";
import DeleteModal from "../../../components/DeleteModal";

const EditUser = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [userForm, setUserForm] = useState(null);
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState({
    open: false,
    id: null
  });
  const handleChange = (e) => {
    setUserForm({ ...userForm, [e.target.name]: e.target.value });
  };
  const { data: userData, isLoading: getUserLoading } = useQuery(
    ["get-user", id],
    () => getUser(id)
  );
  useEffect(() => {
    setUserForm({ ...userData?.data.user });
  }, [userData?.success]);

  const queryClient = useQueryClient();
  const {
    mutate,
    data: updateUserData,
    isSuccess,
    isError
  } = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-users");
    }
  });
  const handleEditUser = () => {
    const dataToSend = _.omit(userForm, [
      "_id",
      "role",
      "hasPassword",
      "verified",
      "isDeleted",
      "createdAt",
      "updatedAt"
    ]);
    const errorsTemp = {};

    if (!dataToSend.firstName) {
      errorsTemp.firstName = "*This field is required";
    }
    if (!dataToSend.lastName) {
      errorsTemp.lastName = "*This field is required";
    }
    if (!dataToSend.email) {
      errorsTemp.email = "*This field is required";
    }
    if (!dataToSend.deviceType) {
      errorsTemp.deviceType = "*This field is required";
    }
    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      mutate({ id: id, data: dataToSend });
    }

    console.log(dataToSend);
  };

  const clearErrors = (e) => {
    setErrors({
      ...errors,
      [e.target.name]: ""
    });
  };
  useEffect(() => {
    if (updateUserData?.success) {
      setShowAlert(true);
    }
    return () => {
      setShowAlert(false);
    };
  }, [updateUserData?.success]);
  if (getUserLoading) {
    return "...is loading";
  }
  return (
    <>
      {userForm && (
        <div className="page-wrapper">
          <div className="page-settings">
            <div className="page-infos">
              <IconButton onClick={() => navigate("/dashboard/users")}>
                <ArrowCircleLeftIcon
                  color="primary"
                  style={{ width: 34, height: 34 }}
                />
              </IconButton>
              <h5 className="page-title">User / Edit</h5>
            </div>
            <div className="settings-buttons">
              <Button
                variant="outlined"
                startIcon={<InfoIcon />}
                className=""
                onClick={() => navigate(`/dashboard/show/user/${id}`)}
              >
                Show more
              </Button>

              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                className="cancel-button"
                onClick={() =>
                  setShowDeleteModal({
                    open: true,
                    id: id
                  })
                }
              >
                Delete
              </Button>
            </div>
          </div>
          <div className="show-more-infos-content">
            <List>
              <ListItem>
                <ListItemText primary="First Name:" />
                <div style={{ width: "50%" }}>
                  <TextField
                    name="firstName"
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    value={userForm.firstName}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    error={errors.firstName ? true : false}
                    onFocus={clearErrors}
                  />
                  {errors.firstName && (
                    <p className="edit-user-error-msg">{errors.firstName}</p>
                  )}
                </div>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="Last Name:" />
                <div style={{ width: "50%" }}>
                  <TextField
                    name="lastName"
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    value={userForm.lastName}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    error={errors.lastName ? true : false}
                    onFocus={clearErrors}
                  />
                  {errors.lastName && (
                    <p className="edit-user-error-msg">{errors.lastName}</p>
                  )}
                </div>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="Email:" />
                <div style={{ width: "50%" }}>
                  <TextField
                    name="email"
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    value={userForm.email}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    error={errors.email ? true : false}
                    onFocus={clearErrors}
                  />
                  {errors.email && (
                    <p className="edit-user-error-msg">{errors.email}</p>
                  )}
                </div>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="Device Type:" />
                <div style={{ width: "50%" }}>
                  <TextField
                    name="deviceType"
                    id="outlined-basic"
                    label="Device Type"
                    variant="outlined"
                    value={userForm.deviceType}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    error={errors.deviceType ? true : false}
                    onFocus={clearErrors}
                  />
                  {errors.deviceType && (
                    <p className="edit-user-error-msg">{errors.deviceType}</p>
                  )}
                </div>
              </ListItem>
              <Divider />
              <ListItem style={{ justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  className=""
                  onClick={handleEditUser}
                >
                  Edit user infos
                </Button>
              </ListItem>
              <Divider />
            </List>
            {showAlert && (
              <>
                <Alert
                  severity="success"
                  onClose={() => {
                    setShowAlert(false);
                  }}
                >
                  User is updated!
                </Alert>
              </>
            )}
          </div>
        </div>
      )}
      {showDeleteModal.open && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          user="User"
          queryFnt={deleteUser}
          queryKey="list-users"
        />
      )}
    </>
  );
};

export default EditUser;
