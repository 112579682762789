import React, { useState } from "react";
import { Badge, Button } from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CategoryTable from "./components/CategoryTable";
import AddCategoryModal from "./components/AddCategoryModal";
import FilterCategory from "./components/FilterCategory";
import "../../styles/category.css";
import { useQuery } from "react-query";
import { listCategories } from "../../services/categoryService";

const Category = () => {
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const {
    data: categories,
    isSuccess,
    isError,
    isLoading
  } = useQuery("list-categories", listCategories);
  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID"
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name"
    },
    {
      id: "description",
      numeric: false,
      disablePadding: true,
      label: "Description"
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: true,
      label: "Created At"
    },
    {
      id: "updatedAt",
      numeric: false,
      disablePadding: true,
      label: "Updated At"
    }
  ];

  return (
    <div className="page-wrapper">
      <div className="page-settings">
        <div className="page-infos">
          <h5 className="page-title">Category</h5>
          <Badge badgeContent={categories?.data.totalResults} color="primary">
            <CategoryIcon color="action" />
          </Badge>
        </div>
        <div className="settings-buttons">
          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => setShowAddCategory(true)}
          >
            Add Category
          </Button>
          {/* <Button
            variant="contained"
            startIcon={<FilterAltIcon />}
            onClick={() => setShowFilter(true)}
          >
            Filter
          </Button> */}
        </div>
      </div>
      <CategoryTable headCells={headCells} rows={categories?.data.categories} />
      {showAddCategory && (
        <AddCategoryModal
          showAddCategory={showAddCategory}
          setShowAddCategory={setShowAddCategory}
        />
      )}
      {showFilter && (
        <FilterCategory showFilter={showFilter} setShowFilter={setShowFilter} />
      )}
    </div>
  );
};

export default Category;
