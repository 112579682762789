import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider
} from "@mui/material";
import _ from "lodash";
import { Buffer } from "buffer";
import "../../../styles/users.css";
import { useQuery } from "react-query";
import { getComment, deleteComment } from "../../../services/commentsService";
import { getUser } from "../../../services/usersService";
import { getJoke } from "../../../services/jokeService";
import DeleteModal from "../../../components/DeleteModal";

const ShowMoreComment = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState({
    open: false,
    id: null
  });
  const { data: commentData, isLoading: getCommentLoading } = useQuery(
    ["get-comment", id],
    () => getComment(id)
  );
  const { data: userData, isLoading: getUserLoading } = useQuery(
    ["get-user", commentData?.data.comment.user],
    () => getUser(commentData?.data.comment.user)
  );
  const { data: jokeData, isLoading: getJokeLoading } = useQuery(
    ["get-joke", commentData?.data.comment.joke],
    () => getJoke(commentData?.data.comment.joke)
  );

  if (getCommentLoading || getUserLoading || getJokeLoading) {
    return "...is loading";
  }
  return (
    <>
      <div className="page-wrapper">
        <div className="page-settings">
          <div className="page-infos">
            <IconButton onClick={() => navigate("/dashboard/comments")}>
              <ArrowCircleLeftIcon
                color="primary"
                style={{ width: 34, height: 34 }}
              />
            </IconButton>
            <h5 className="page-title">Comment / Show more infos</h5>
          </div>
          <div className="settings-buttons">
            {/* <Button
              variant="outlined"
              startIcon={<EditIcon />}
              className=""
              onClick={() => navigate(`/dashboard/edit/comment/${id}`)}
            >
              Edit
            </Button> */}

            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              className="cancel-button"
              onClick={() =>
                setShowDeleteModal({
                  open: true,
                  id: id
                })
              }
            >
              Delete
            </Button>
          </div>
        </div>
        <div className="show-more-infos-content">
          <List>
            <ListItem>
              <ListItemText primary="ID:" style={{ width: "50%" }} />
              <ListItemText
                primary={commentData?.data.comment._id}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Text:" style={{ width: "50%" }} />
              <ListItemText
                primary={commentData?.data.comment.comment}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />

            <ListItem>
              <ListItemText primary="Joke:" style={{ width: "50%" }} />
              <ListItemText
                primary={
                  jokeData?.data.joke.type === "TEXT"
                    ? jokeData?.data.joke.text
                    : jokeData?.data.joke.type === "VIDEO"
                    ? jokeData?.data.joke.videoTitle
                    : jokeData?.data.joke.imageTitle
                }
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="User:" style={{ width: "50%" }} />
              <ListItemText
                primary={userData?.data.user.firstName.concat(
                  " ",
                  userData?.data.user.lastName
                )}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />

            <ListItem>
              <ListItemText primary="Created At:" style={{ width: "50%" }} />
              <ListItemText
                primary={commentData?.data.comment.createdAt}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Updated At:" style={{ width: "50%" }} />
              <ListItemText
                primary={commentData?.data.comment.updatedAt}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
          </List>
        </div>
      </div>
      {showDeleteModal.open && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          item="Comment"
          queryFnt={deleteComment}
          queryKey="list-comments"
        />
      )}
    </>
  );
};

export default ShowMoreComment;
