import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../providers/Global";
import { logInAdmin } from "../../services/authService";
import "../../styles/login.css";

const Login = () => {
  const navigate = useNavigate();
  const { setCurrentUser } = useContext(GlobalContext);

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: ""
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };

  const { mutate, isSuccess, data, isError, reset } = useMutation(logInAdmin);
  const submit = () => {
    const errorsTemp = {};

    if (!loginForm.email) {
      errorsTemp.email = "*This field is required";
    }
    if (!loginForm.password) {
      errorsTemp.password = "*This field is required";
    }
    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      mutate(loginForm);
    }
  };
  let currentToken = localStorage.getItem("token");

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem("token", data.data.token);
      setCurrentUser(data?.data);
      navigate("/dashboard");
    }
    if (isError) {
      const errorsTemp = {};
      errorsTemp.login = "*Please enter your email and password";
      setErrors(errorsTemp);
    }
  }, [isSuccess, isError]);

  return (
    <>
      {!currentToken && (
        <div className="login-wrapper">
          <div className="login-content">
            <h1 className="login-title">Welcome</h1>
            <h4 className="login-descreption">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </h4>
            <div className="login-inputs-container">
              <TextField
                name="email"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={loginForm.email}
                onChange={handleChange}
                error={errors.email ? true : errors.login ? true : false}
              />
              {errors.email && (
                <p className="login-error-msg">{errors.email}</p>
              )}
              <FormControl
                sx={{ m: 1, width: "100%", margin: 0 }}
                variant="outlined"
                error={errors.password ? true : errors.login ? true : false}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  name="password"
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={loginForm.password}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(e) => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              {errors.password && (
                <p className="login-error-msg">{errors.password}</p>
              )}
            </div>
            {errors.login && (
              <p className="login-error-msg" style={{ marginTop: 10 }}>
                {errors.login}
              </p>
            )}
            <Button
              variant="contained"
              className="login-button"
              onClick={submit}
            >
              Log in
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
