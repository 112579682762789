import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider
} from "@mui/material";
import "../../../styles/users.css";
import { useQuery } from "react-query";
import { getUser, deleteUser } from "../../../services/usersService";
import DeleteModal from "../../../components/DeleteModal";
import _ from "lodash";

const ShowMoreUser = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState({
    open: false,
    id: null
  });
  const { data: userData, isLoading: getUserLoading } = useQuery(
    ["get-user", id],
    () => getUser(id)
  );

  if (getUserLoading) {
    return "...is loading";
  }
  return (
    <>
      <div className="page-wrapper">
        <div className="page-settings">
          <div className="page-infos">
            <IconButton onClick={() => navigate("/dashboard/users")}>
              <ArrowCircleLeftIcon
                color="primary"
                style={{ width: 34, height: 34 }}
              />
            </IconButton>
            <h5 className="page-title">User / Show more infos</h5>
          </div>
          <div className="settings-buttons">
            <Button
              variant="outlined"
              startIcon={<EditIcon />}
              className=""
              onClick={() => navigate(`/dashboard/edit/user/${id}`)}
            >
              Edit
            </Button>

            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              className="cancel-button"
              onClick={() =>
                setShowDeleteModal({
                  open: true,
                  id: id
                })
              }
            >
              Delete
            </Button>
          </div>
        </div>
        <div className="show-more-infos-content">
          <List>
            <ListItem>
              <ListItemText primary="ID:" style={{ width: "50%" }} />
              <ListItemText
                primary={userData?.data.user._id}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="First Name:" style={{ width: "50%" }} />
              <ListItemText
                primary={userData?.data.user.firstName}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Last Name:" style={{ width: "50%" }} />
              <ListItemText
                primary={userData?.data.user.lastName}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Email:" style={{ width: "50%" }} />
              <ListItemText
                primary={userData?.data.user.email}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Device Type:" style={{ width: "50%" }} />
              <ListItemText
                primary={userData?.data.user.deviceType}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Role:" style={{ width: "50%" }} />
              <ListItemText
                primary={userData?.data.user.role}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Created At:" style={{ width: "50%" }} />
              <ListItemText
                primary={userData?.data.user.createdAt}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Updated At:" style={{ width: "50%" }} />
              <ListItemText
                primary={userData?.data.user.updatedAt}
                style={{ width: "50%" }}
              />
            </ListItem>
            <Divider />
          </List>
        </div>
      </div>
      {showDeleteModal.open && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          user="User"
          queryFnt={deleteUser}
          queryKey="list-users"
        />
      )}
    </>
  );
};

export default ShowMoreUser;
