import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NavBar from "../layout/NavBar";
import Users from "../pages/users/Users";
import Jokes from "../pages/jokes/Jokes";
import Category from "../pages/category/Category";
import ShowMoreUser from "../pages/users/components/ShowMoreUser";
import ShowMoreCategory from "../pages/category/components/ShowMoreCategory";
import ShowMoreJoke from "../pages/jokes/components/ShowMoreJoke";
import EditUser from "../pages/users/components/EditUser";
import EditCategory from "../pages/category/components/EditCategory";
import EditJoke from "../pages/jokes/components/EditJoke";
import Comments from "../pages/comments/Comments";
import ShowMoreComment from "../pages/comments/components/ShowMoreComment";
import EditComment from "../pages/comments/components/EditComment";
import Settings from "../pages/admin/Settings";
import Account from "../pages/admin/Account";
import Dashboard from "../pages/dashboard/Dashboard";
const NewRoutes = () => {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/my-account" element={<Account />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/users" element={<Users />} />
        <Route exact path="/show/user/:id" element={<ShowMoreUser />} />
        <Route exact path="/edit/user/:id" element={<EditUser />} />
        <Route exact path="/jokes" element={<Jokes />} />
        <Route exact path="/show/joke/:id" element={<ShowMoreJoke />} />
        <Route exact path="/edit/joke/:id" element={<EditJoke />} />
        <Route exact path="/categories" element={<Category />} />
        <Route exact path="/show/category/:id" element={<ShowMoreCategory />} />
        <Route exact path="/edit/category/:id" element={<EditCategory />} />
        <Route exact path="/comments" element={<Comments />} />
        <Route exact path="/show/comment/:id" element={<ShowMoreComment />} />
        <Route exact path="/edit/comment/:id" element={<EditComment />} />
      </Routes>
    </>
  );
};

export default NewRoutes;
