import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Alert
} from "@mui/material";
import "../../../styles/jokes.css";
import _ from "lodash";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getComment,
  updateComment,
  deleteComment
} from "../../../services/commentsService";
import { getUser } from "../../../services/usersService";
import { getJoke } from "../../../services/jokeService";
import DeleteModal from "../../../components/DeleteModal";

const EditComment = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [commentForm, setCommentForm] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState({
    open: false,
    id: null
  });
  const handleChange = (e) => {
    setCommentForm({ ...commentForm, [e.target.name]: e.target.value });
  };
  const { data: commentData, isLoading: getCommentLoading } = useQuery(
    ["get-comment", id],
    () => getComment(id)
  );
  const { data: userData, isLoading: getUserLoading } = useQuery(
    ["get-user", commentData?.data.comment.user],
    () => getUser(commentData?.data.comment.user)
  );
  const { data: jokeData, isLoading: getJokeLoading } = useQuery(
    ["get-joke", commentData?.data.comment.joke],
    () => getJoke(commentData?.data.comment.joke)
  );
  useEffect(() => {
    setCommentForm({ ...commentData?.data.comment });
  }, [commentData?.success]);

  const queryClient = useQueryClient();
  const {
    mutate,
    data: updateCommentData,
    isSuccess: update_comment_success,
    isError: updated_comment_error
  } = useMutation(updateComment, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-comments");
    }
  });
  const handleEditComment = () => {
    const dataToSend = _.omit(commentForm, [
      "_id",
      "joke",
      "user",
      "isDeleted",
      "createdAt",
      "updatedAt"
    ]);
    mutate({ id: commentForm._id, data: dataToSend });
  };
  useEffect(() => {
    if (updateCommentData?.success) {
      setShowAlert(true);
    }
    return () => {
      setShowAlert(false);
    };
  }, [updateCommentData?.success]);
  if (getCommentLoading || getUserLoading || getJokeLoading) {
    return "...is loading";
  }
  return (
    <>
      {commentForm && (
        <div className="page-wrapper">
          <div className="page-settings">
            <div className="page-infos">
              <IconButton onClick={() => navigate("/dashboard/comments")}>
                <ArrowCircleLeftIcon
                  color="primary"
                  style={{ width: 34, height: 34 }}
                />
              </IconButton>
              <h5 className="page-title">Comment / Edit</h5>
            </div>
            <div className="settings-buttons">
              <Button
                variant="outlined"
                startIcon={<InfoIcon />}
                className=""
                onClick={() => navigate(`/dashboard/show/comment/${id}`)}
              >
                Show more
              </Button>

              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                className="cancel-button"
                onClick={() =>
                  setShowDeleteModal({
                    open: true,
                    id: id
                  })
                }
              >
                Delete
              </Button>
            </div>
          </div>
          <div className="show-more-infos-content">
            <List>
              <ListItem>
                <ListItemText primary="Text:" style={{ width: "50%" }} />
                <TextField
                  name="comment"
                  id="outlined-basic"
                  label="Comment"
                  variant="outlined"
                  value={commentForm.comment}
                  onChange={handleChange}
                  style={{ width: "50%" }}
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="Joke:" style={{ width: "50%" }} />
                <TextField
                  name="joke"
                  id="outlined-basic"
                  label="Joke"
                  variant="outlined"
                  value={
                    jokeData?.data.joke.type === "TEXT"
                      ? jokeData?.data.joke.text
                      : jokeData?.data.joke.type === "VIDEO"
                      ? jokeData?.data.joke.videoTitle
                      : jokeData?.data.joke.imageTitle
                  }
                  style={{ width: "50%" }}
                  disabled
                  multiline
                  rows={6}
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="User:" style={{ width: "50%" }} />
                <TextField
                  name="user"
                  id="outlined-basic"
                  label="User"
                  variant="outlined"
                  value={userData?.data.user.firstName.concat(
                    " ",
                    userData?.data.user.lastName
                  )}
                  style={{ width: "50%" }}
                  disabled
                />
              </ListItem>
              <Divider />
              <ListItem style={{ justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  className=""
                  onClick={handleEditComment}
                >
                  Edit comment infos
                </Button>
              </ListItem>
              <Divider />
            </List>
            {showAlert && (
              <>
                <Alert
                  severity="success"
                  onClose={() => {
                    setShowAlert(false);
                  }}
                >
                  Comment is updated!
                </Alert>
              </>
            )}
          </div>
        </div>
      )}

      {showDeleteModal.open && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          item="Comment"
          queryFnt={deleteComment}
          queryKey="list-comments"
        />
      )}
    </>
  );
};

export default EditComment;
