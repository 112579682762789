import React, { useState } from "react";
import { Badge, Button, Pagination } from "@mui/material";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import JokesTable from "./components/JokesTable";
import AddJokeModal from "./components/AddJokeModal";
import "../../styles/jokes.css";
import FilterJoke from "./components/FilterJoke";
import { useQuery } from "react-query";
import {
  listJokes,
  filterJokeByCategory,
  filterJokeByType,
  jokePagination
} from "../../services/jokeService";

const Jokes = () => {
  const [showAddJoke, setShowAddJoke] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [jokeType, setJokeType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: jokes,
    isSuccess,
    isError,
    isLoading: isLoading_jokes
  } = useQuery(["list-jokes", currentPage], () => jokePagination(currentPage), {
    keepPreviousData: true
  });

  const {
    data: jokeByCategoryData,
    isLoading: getJokeByCategoryLoading,
    refetch: refetchByCategory
  } = useQuery(
    ["get-joke-category", categoryId],
    () => filterJokeByCategory(categoryId),
    {
      enabled: false
    }
  );

  const {
    data: jokeByTypeData,
    isLoading: getJokeByTypeLoading,
    refetch: refetchByType
  } = useQuery(["get-joke-type", jokeType], () => filterJokeByType(jokeType), {
    enabled: false
  });

  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID"
    },
    {
      id: "text",
      numeric: false,
      disablePadding: true,
      label: "Text"
    },
    {
      id: "imageUrl",
      numeric: false,
      disablePadding: true,
      label: "Image Url"
    },
    {
      id: "imageTitle",
      numeric: false,
      disablePadding: true,
      label: "Image Title"
    },
    {
      id: "videoUrl",
      numeric: false,
      disablePadding: true,
      label: "Video Url"
    },
    {
      id: "videoTitle",
      numeric: false,
      disablePadding: true,
      label: "Video Title"
    },

    {
      id: "thumb",
      numeric: false,
      disablePadding: true,
      label: "Thumb"
    },
    {
      id: "description",
      numeric: false,
      disablePadding: true,
      label: "Description"
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: "Type"
    },
    {
      id: "source",
      numeric: false,
      disablePadding: true,
      label: "Source"
    },
    {
      id: "language",
      numeric: false,
      disablePadding: true,
      label: "Language"
    },
    {
      id: "category",
      numeric: false,
      disablePadding: true,
      label: "Category"
    },
    {
      id: "user",
      numeric: false,
      disablePadding: true,
      label: "User"
    },
    {
      id: "totalUpvoted",
      numeric: false,
      disablePadding: true,
      label: "Total Upvoted"
    },
    {
      id: "totalDownvoted",
      numeric: false,
      disablePadding: true,
      label: "Total Downvoted"
    },
    {
      id: "comments",
      numeric: false,
      disablePadding: true,
      label: "Number of comments"
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: true,
      label: "Created At"
    },
    {
      id: "updatedAt",
      numeric: false,
      disablePadding: true,
      label: "Updated At"
    }
  ];
  if (isLoading_jokes) {
    return "...is loading";
  }
  return (
    <div className="page-wrapper">
      <div className="page-settings">
        <div className="page-infos">
          <h5 className="page-title">Jokes</h5>
          <Badge
            badgeContent={
              jokeByTypeData?.data.totalResults
                ? jokeByTypeData?.data.totalResults
                : jokeByCategoryData?.data.totalResults
                ? jokeByCategoryData?.data.totalResults
                : jokes?.data.totalResults
            }
            color="primary"
          >
            <EmojiEmotionsIcon color="action" />
          </Badge>
        </div>
        <div className="settings-buttons">
          <Button
            variant="outlined"
            startIcon={<AddReactionIcon />}
            onClick={() => setShowAddJoke(true)}
          >
            Add Joke
          </Button>
          <Button
            variant="contained"
            startIcon={<FilterAltIcon />}
            onClick={() => setShowFilter(true)}
          >
            Filter
          </Button>
        </div>
      </div>
      <Pagination
        style={{ marginBottom: 20 }}
        page={currentPage}
        count={
          jokeByTypeData?.data.totalPages
            ? jokeByTypeData?.data.totalPages
            : jokeByCategoryData?.data.totalPages
            ? jokeByCategoryData?.data.totalPages
            : jokes?.data.totalPages
        }
        variant="outlined"
        shape="rounded"
        hidePrevButton
        hideNextButton
        onChange={(event, value) => setCurrentPage(value)}
      />
      <JokesTable
        headCells={headCells}
        rows={
          jokeByTypeData?.data.jokes
            ? jokeByTypeData?.data.jokes
            : jokeByCategoryData?.data.jokes
            ? jokeByCategoryData?.data.jokes
            : jokes?.data.jokes
        }
      />
      {showAddJoke && (
        <AddJokeModal
          showAddJoke={showAddJoke}
          setShowAddJoke={setShowAddJoke}
        />
      )}
      {showFilter && (
        <FilterJoke
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          categoryId={categoryId}
          setCategoryId={setCategoryId}
          refetchByCategory={refetchByCategory}
          jokeType={jokeType}
          setJokeType={setJokeType}
          refetchByType={refetchByType}
        />
      )}
    </div>
  );
};

export default Jokes;
