import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  IconButton
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import DeleteModal from "../../../components/DeleteModal";
import { deleteJoke } from "../../../services/jokeService";

const JokesTable = ({ headCells, rows }) => {
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState({
    open: false,
    id: null
  });

  return (
    <>
      <Paper>
        <TableContainer>
          <Table
            sx={{
              width: "100%",
              background: "#fff",
              borderBottom: "1px solid #dde1e5"
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox color="primary" />
                </TableCell> */}
                {headCells.map((el, i) => (
                  <TableCell key={i}>{el.label}</TableCell>
                ))}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows.map((row, _id) => (
                  <TableRow
                    key={_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {/* <TableCell padding="checkbox">
                        <Checkbox color="primary" />
                      </TableCell> */}
                    <TableCell component="th" scope="row">
                      {row._id}
                    </TableCell>
                    <TableCell>{row.text}</TableCell>
                    <TableCell>{row.imageUrl}</TableCell>
                    <TableCell>{row.imageTitle}</TableCell>
                    <TableCell>{row.videoUrl}</TableCell>
                    <TableCell>{row.videoTitle}</TableCell>
                    <TableCell>{row.thumb}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.source}</TableCell>
                    <TableCell>{row.language}</TableCell>
                    <TableCell>{row.category?.name}</TableCell>
                    <TableCell>{row.user?.fullName}</TableCell>
                    <TableCell>{row.totalUpvoted}</TableCell>
                    <TableCell>{row.totalDownvoted}</TableCell>
                    <TableCell>{row.comments}</TableCell>
                    <TableCell>{row.createdAt}</TableCell>
                    <TableCell>{row.updatedAt}</TableCell>
                    <TableCell>
                      <div className="menu-table">
                        <IconButton
                          onClick={() =>
                            navigate(`/dashboard/show/joke/${row._id}`)
                          }
                        >
                          <InfoIcon color="primary" />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            navigate(`/dashboard/edit/joke/${row._id}`)
                          }
                        >
                          <EditIcon color="primary" />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            setShowDeleteModal({
                              open: true,
                              id: row._id
                            })
                          }
                        >
                          <DeleteIcon style={{ color: "#f6416c" }} />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {showDeleteModal.open && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          item="Joke"
          queryFnt={deleteJoke}
          queryKey="list-jokes"
        />
      )}
    </>
  );
};

export default JokesTable;
