import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import "../../../styles/jokes.css";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getJoke, updateJoke, deleteJoke } from "../../../services/jokeService";
import _ from "lodash";
import { listUsers } from "../../../services/usersService";
import { listCategories } from "../../../services/categoryService";
import DeleteModal from "../../../components/DeleteModal";

const EditJoke = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [jokeForm, setJokeForm] = useState(null);
  const [showJokeImage, setShowJokeImage] = useState(null);
  const [showJokeVideo, setShowJokeVideo] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [errors, setErrors] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState({
    open: false,
    id: null
  });
  const addJokeImage = useRef();
  const addJokeVideo = useRef();
  const handleChange = (e) => {
    setJokeForm({ ...jokeForm, [e.target.name]: e.target.value });
  };

  const handleAddJokeImage = (e) => {
    if (e.target.files[0].size > 2000000) {
      let errorsTemp = {};
      errorsTemp.file = "*This field size must be less then 2mo";
      setErrors(errorsTemp);
    } else {
      setJokeForm({ ...jokeForm, file: e.target.files[0] });
      console.log(jokeForm.file);
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setShowJokeImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const handleAddJokeVideo = (e) => {
    if (e.target.files[0].size > 20000000) {
      let errorsTemp = {};
      errorsTemp.file = "*This field size must be less then 20mo";
      setErrors(errorsTemp);
    } else {
      setJokeForm({ ...jokeForm, file: e.target.files[0] });
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setShowJokeVideo(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const { data: jokeData, isLoading: getJokeLoading } = useQuery(
    ["get-joke", id],
    () => getJoke(id)
  );
  const {
    data: users,
    isSuccess: isSuccess_users,
    isError: isError_users,
    isLoading: isLoading_users
  } = useQuery("list-users", listUsers);
  const {
    data: categories,
    isSuccess: isSuccess_categories,
    isError: isError_categories,
    isLoading: isLoading_categories
  } = useQuery("list-categories", listCategories);

  useEffect(() => {
    if (
      jokeData?.data.joke.type === "IMAGE" ||
      jokeData?.data.joke.type === "GIF"
    ) {
      setShowJokeImage(
        `https://nookat-new-api.yosrycoding.dev/${jokeData?.data.joke.imageUrl}`
      );
    }
    if (jokeData?.data.joke.type === "VIDEO") {
      setShowJokeVideo(
        `https://nookat-new-api.yosrycoding.dev/${jokeData?.data.joke.videoUrl}`
      );
    }
    const jokeFormTemp = {};
    if (jokeData?.success) {
      jokeFormTemp.text = jokeData.data.joke.text;
      jokeFormTemp.file = null;
      jokeFormTemp.imageUrl = "";
      jokeFormTemp.imageTitle = jokeData.data.joke.imageTitle;
      jokeFormTemp.videoUrl = "";
      jokeFormTemp.videoTitle = jokeData.data.joke.videoTitle;
      jokeFormTemp.thumb = "";
      jokeFormTemp.description = jokeData.data.joke.description;
      jokeFormTemp.type = jokeData.data.joke.type;
      jokeFormTemp.totalUpvoted = jokeData.data.joke.totalUpvoted;
      jokeFormTemp.totalDownvoted = jokeData.data.joke.totalDownvoted;
      jokeFormTemp.source =
        jokeData.data.joke.type === "TEXT" ? "" : jokeData.data.joke.source;
      jokeFormTemp.category = jokeData.data.joke.category._id;
      jokeFormTemp.user = jokeData.data.joke.user._id;
    }
    setJokeForm(jokeFormTemp);
  }, [jokeData?.success]);

  const queryClient = useQueryClient();
  const {
    mutate,
    data: updateJokeData,
    isSuccess,
    isError
  } = useMutation(updateJoke, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-jokes");
    }
  });

  const handleEditJoke = () => {
    const errorsTemp = {};
    if (isNaN(jokeForm.totalDownvoted) || jokeForm.totalDownvoted < 0) {
      errorsTemp.totalDownvoted = "*Please enter a valid positive number";
    }
    if (isNaN(jokeForm.totalUpvoted) || jokeForm.totalUpvoted < 0) {
      errorsTemp.totalUpvoted = "*Please enter a valid positive number";
    }
    if (jokeForm.type === "TEXT") {
      if (!jokeForm.text) {
        errorsTemp.text = "*This field is required";
      }
    }
    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      const formData = new FormData();
      for (var key in jokeForm) {
        if (Boolean(jokeForm[key])) {
          formData.append(key, jokeForm[key]);
        }
      }
      mutate({ id: id, data: formData });
    }
  };
  const clearErrors = (e) => {
    setErrors({
      ...errors,
      [e.target.name]: ""
    });
  };
  useEffect(() => {
    if (updateJokeData?.success) {
      setShowAlert(true);
    }
    return () => {
      setShowAlert(false);
    };
  }, [updateJokeData?.success]);
  if (getJokeLoading || isLoading_users || isLoading_categories) {
    return "...is loading";
  }
  return (
    <>
      {jokeForm && (
        <div className="page-wrapper">
          <div className="page-settings">
            <div className="page-infos">
              <IconButton onClick={() => navigate("/dashboard/jokes")}>
                <ArrowCircleLeftIcon
                  color="primary"
                  style={{ width: 34, height: 34 }}
                />
              </IconButton>
              <h5 className="page-title">Joke / Edit</h5>
            </div>
            <div className="settings-buttons">
              <Button
                variant="outlined"
                startIcon={<InfoIcon />}
                className=""
                onClick={() => navigate(`/dashboard/show/joke/${id}`)}
              >
                Show more
              </Button>

              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                className="cancel-button"
                onClick={() =>
                  setShowDeleteModal({
                    open: true,
                    id: id
                  })
                }
              >
                Delete
              </Button>
            </div>
          </div>
          <div className="show-more-infos-content">
            <List>
              <ListItem>
                <ListItemText primary="Type:" style={{ width: "50%" }} />
                <ListItemText
                  primary={jokeForm.type}
                  style={{ width: "50%" }}
                />
              </ListItem>

              <Divider />
              <ListItem>
                <ListItemText primary="Category:" />
                <FormControl style={{ width: "50%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                  <Select
                    name="category"
                    label="Category"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={jokeForm.category ?? " "}
                    onChange={handleChange}
                  >
                    {categories?.data.categories.map((el, i) => (
                      <MenuItem key={i} value={el._id}>
                        {el.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ListItem>
              <Divider />
              {jokeForm.type === "TEXT" ? (
                <>
                  <ListItem>
                    <ListItemText primary="Text:" />
                    <div style={{ width: "50%" }}>
                      <TextField
                        name="text"
                        id="outlined-basic"
                        label="Text"
                        variant="outlined"
                        value={jokeForm.text}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        error={errors.text ? true : false}
                        onFocus={clearErrors}
                      />
                      {errors.text && (
                        <p className="add-joke-error-msg">{errors.text}</p>
                      )}
                    </div>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="Description:" />
                    <TextField
                      name="description"
                      id="outlined-basic"
                      label="Description"
                      variant="outlined"
                      value={jokeForm.description}
                      onChange={handleChange}
                      style={{ width: "50%" }}
                    />
                  </ListItem>
                  <Divider />
                </>
              ) : jokeForm.type === "VIDEO" ? (
                <>
                  <ListItem>
                    <ListItemText primary="Source:" />
                    <FormControl style={{ width: "50%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Source
                      </InputLabel>
                      <Select
                        name="source"
                        label="Source"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={jokeForm.source}
                        onChange={handleChange}
                      >
                        <MenuItem value="LOCAL">Local</MenuItem>
                      </Select>
                    </FormControl>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="Video title:" />
                    <TextField
                      name="videoTitle"
                      id="outlined-basic"
                      label="Video Title"
                      variant="outlined"
                      value={jokeForm.videoTitle}
                      onChange={handleChange}
                      style={{ width: "50%" }}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="Video url:" />
                    {jokeForm.source === "LOCAL" ? (
                      <div className="edit-joke-image" style={{ width: "50%" }}>
                        <video alt="" src={showJokeVideo} controls />
                        <IconButton
                          className="add-joke-image-bttn"
                          onClick={() => addJokeVideo.current.click()}
                        >
                          <VideoFileIcon color="primary" />
                        </IconButton>
                        <label
                          ref={addJokeVideo}
                          htmlFor="importVideoJoke"
                        ></label>
                        <input
                          type="file"
                          id="importVideoJoke"
                          onChange={handleAddJokeVideo}
                          accept=".mp4, .3gp, .mov"
                        />
                      </div>
                    ) : (
                      <TextField
                        name="videoUrl"
                        id="outlined-basic"
                        label="Video Url"
                        variant="outlined"
                        value={jokeForm.videoUrl}
                        onChange={handleChange}
                        style={{ width: "50%" }}
                      />
                    )}
                  </ListItem>
                  <Divider />

                  <ListItem>
                    <ListItemText primary="Description:" />
                    <TextField
                      name="description"
                      id="outlined-basic"
                      label="Description"
                      variant="outlined"
                      value={jokeForm.description}
                      onChange={handleChange}
                      style={{ width: "50%" }}
                    />
                  </ListItem>
                  <Divider />
                </>
              ) : jokeForm.type === "IMAGE" || jokeForm.type === "GIF" ? (
                <>
                  <ListItem>
                    <ListItemText primary="Source:" />
                    <FormControl style={{ width: "50%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Source
                      </InputLabel>
                      <Select
                        name="source"
                        label="Source"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={jokeForm.source}
                        onChange={handleChange}
                      >
                        <MenuItem value="LOCAL">Local</MenuItem>
                      </Select>
                    </FormControl>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="Image title:" />
                    <TextField
                      name="imageTitle"
                      id="outlined-basic"
                      label="Image Title"
                      variant="outlined"
                      value={jokeForm.imageTitle}
                      onChange={handleChange}
                      style={{ width: "50%" }}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="Image url:" />
                    {jokeForm.source === "LOCAL" ? (
                      <div className="edit-joke-image" style={{ width: "50%" }}>
                        <img alt="" src={showJokeImage} />
                        <IconButton
                          className="add-joke-image-bttn"
                          onClick={() => addJokeImage.current.click()}
                        >
                          <AddPhotoAlternateIcon color="primary" />
                        </IconButton>
                        <label
                          ref={addJokeImage}
                          htmlFor="importImageJoke"
                        ></label>
                        <input
                          type="file"
                          id="importImageJoke"
                          onChange={handleAddJokeImage}
                          accept={
                            jokeForm.type === "IMAGE"
                              ? ".png, .jpg, .jpeg"
                              : ".gif"
                          }
                        />
                      </div>
                    ) : (
                      <TextField
                        name="imageUrl"
                        id="outlined-basic"
                        label="Image Url"
                        variant="outlined"
                        value={jokeForm.imageUrl}
                        onChange={handleChange}
                        style={{ width: "50%" }}
                      />
                    )}
                  </ListItem>
                  <Divider />

                  <ListItem>
                    <ListItemText primary="Description:" />
                    <TextField
                      name="description"
                      id="outlined-basic"
                      label="Description"
                      variant="outlined"
                      value={jokeForm.description}
                      onChange={handleChange}
                      style={{ width: "50%" }}
                    />
                  </ListItem>
                  <Divider />
                </>
              ) : null}
              <ListItem>
                <ListItemText primary="Upvoted:" />
                <div style={{ width: "50%" }}>
                  <TextField
                    name="totalUpvoted"
                    id="outlined-basic"
                    label="Upvoted"
                    variant="outlined"
                    value={jokeForm.totalUpvoted}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    error={errors.totalUpvoted ? true : false}
                    onFocus={clearErrors}
                  />
                  {errors.totalUpvoted && (
                    <p className="add-joke-error-msg">{errors.totalUpvoted}</p>
                  )}
                </div>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="Downvoted:" />
                <div style={{ width: "50%" }}>
                  <TextField
                    name="totalDownvoted"
                    id="outlined-basic"
                    label="Downvoted"
                    variant="outlined"
                    value={jokeForm.totalDownvoted}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    error={errors.totalDownvoted ? true : false}
                    onFocus={clearErrors}
                  />
                  {errors.totalDownvoted && (
                    <p className="add-joke-error-msg">
                      {errors.totalDownvoted}
                    </p>
                  )}
                </div>
              </ListItem>
              <Divider />
              {/* ///button// */}
              <ListItem style={{ justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  className=""
                  onClick={handleEditJoke}
                >
                  Edit joke infos
                </Button>
              </ListItem>
              <Divider />
            </List>
            {showAlert && (
              <>
                <Alert
                  severity="success"
                  onClose={() => {
                    setShowAlert(false);
                  }}
                >
                  Joke is updated!
                </Alert>
              </>
            )}
          </div>
        </div>
      )}
      {showDeleteModal.open && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          user="Joke"
          queryFnt={deleteJoke}
          queryKey="list-jokes"
        />
      )}
    </>
  );
};

export default EditJoke;
