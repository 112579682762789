import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Main from "./Main";
import GlobalContext from "./providers/Global";
const queryClient = new QueryClient();

function App() {
  return (
    <Router>
      <GlobalContext>
        <QueryClientProvider client={queryClient}>
          <Main />
          <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
      </GlobalContext>
    </Router>
  );
}

export default App;
