import api from "../config/api";

// signIn Admin
export const logInAdmin = async (data) => {
  return await api({
    method: "post",
    url: `/api/auth/admins/signin`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// get Admin account
export const adminAccount = () => {
  const token = localStorage.getItem("token");
  return api({
    method: "get",
    url: `/api/admins/me`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

//update Admin
export const updateAdminAccount = (data) => {
  const token = localStorage.getItem("token");
  return api({
    method: "put",
    url: `/api/admins/me`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// update password
export const updatePassword = (data) => {
  const token = localStorage.getItem("token");
  return api({
    method: "patch",
    url: `/api/admins/me/password`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// get Admins list
export const listAdmins = (data) => {
  const token = localStorage.getItem("token");
  return api({
    method: "get",
    url: `/api/admins`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// add Admin
export const createAdmin = (data) => {
  const token = localStorage.getItem("token");
  return api({
    method: "post",
    url: `/api/admins`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// get an admin
export const getAdmin = (id) => {
  const token = localStorage.getItem("token");
  return api({
    method: "get",
    url: `/api/admins/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// delete an admin
export const deleteAdmin = (id) => {
  const token = localStorage.getItem("token");
  return api({
    method: "delete",
    url: `/api/admins/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};
