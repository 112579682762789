import api from "./api";
const AxiosInterceptorsSetup = (logOut) => {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const status = error.response.status;
        if (status === 403 || status === 401) {
          logOut();
        }
      }
      return Promise.reject(error);
    }
  );
};

export default AxiosInterceptorsSetup;
