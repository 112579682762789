import React from "react";
import {
  Modal,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider
} from "@mui/material";
import { getAdmin } from "../../../services/authService";
import { useQuery } from "react-query";

const ShowInfosAdminModal = ({ showInfosModal, setShowInfosModal }) => {
  const date = new Date();
  const { data: adminData, isLoading: getAdminLoading } = useQuery(
    ["get-admin", showInfosModal.id],
    () => getAdmin(showInfosModal.id)
  );

  const handleClose = () => {
    setShowInfosModal({
      open: false,
      id: null
    });
  };
  //   if (getAdminLoading) {
  //     return "...is loading";
  //   }
  return (
    <Modal open={showInfosModal.open} onClose={handleClose}>
      <Grid
        container
        item
        xs={7}
        md={6}
        direction="column"
        className="add-admin-modal"
      >
        <h1 className="modal-title">Show Infos</h1>
        {getAdminLoading ? (
          "...is loading"
        ) : (
          <>
            <div className="add-admin-modal-content">
              <List style={{ width: "100%" }}>
                <ListItem>
                  <ListItemText primary="ID:" style={{ width: "50%" }} />
                  <ListItemText
                    primary={adminData?.data.admin._id}
                    style={{ width: "50%" }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Full Name:" style={{ width: "50%" }} />
                  <ListItemText
                    primary={adminData?.data.admin.fullName}
                    style={{ width: "50%" }}
                  />
                </ListItem>
                <Divider />

                <ListItem>
                  <ListItemText primary="Email:" style={{ width: "50%" }} />
                  <ListItemText
                    primary={adminData?.data.admin.email}
                    style={{ width: "50%" }}
                  />
                </ListItem>
                <Divider />

                <ListItem>
                  <ListItemText primary="Role:" style={{ width: "50%" }} />
                  <ListItemText
                    primary={adminData?.data.admin.role}
                    style={{ width: "50%" }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Created At:"
                    style={{ width: "50%" }}
                  />
                  <ListItemText
                    primary={adminData?.data.admin.createdAt}
                    style={{ width: "50%" }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Updated At:"
                    style={{ width: "50%" }}
                  />
                  <ListItemText
                    primary={adminData?.data.admin.updatedAt}
                    style={{ width: "50%" }}
                  />
                </ListItem>
                <Divider />
              </List>
            </div>
            <div className="modal-buttons">
              <Button
                variant="outlined"
                className="cancel-button"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </>
        )}
      </Grid>
    </Modal>
  );
};

export default ShowInfosAdminModal;
