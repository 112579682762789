import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import { useQuery } from "react-query";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { adminAccount } from "../../services/authService";
import AdminInfos from "./accountComponents/AdminInfos";
import UpdatePassword from "./accountComponents/UpdatePassword";
import "../../styles/admin.css";

const Account = () => {
  const [accountForm, setAccountForm] = useState({
    fullName: "",
    email: ""
  });

  const { data: adminAccountData, isLoading: adminAccountLoading } = useQuery(
    "admin-account",
    adminAccount
  );

  useEffect(() => {
    if (adminAccountData?.success) {
      setAccountForm({
        ...accountForm,
        fullName: adminAccountData?.data.admin.fullName,
        email: adminAccountData?.data.admin.email
      });
    }
  }, [adminAccountData?.success]);

  if (adminAccountLoading) {
    return "...is loading";
  }
  return (
    <div className="page-wrapper">
      <div className="page-settings">
        <div className="page-infos">
          <IconButton>
            <AccountBoxIcon
              style={{ width: 34, height: 34, color: "#f6416c" }}
            />
          </IconButton>
          <h5 className="page-title">My account</h5>
        </div>
      </div>
      <AdminInfos
        accountForm={accountForm}
        setAccountForm={setAccountForm}
        adminAccountData={adminAccountData}
      />
      <UpdatePassword />
    </div>
  );
};

export default Account;
