import React, { useState } from "react";
import {
  Modal,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useMutation, useQueryClient } from "react-query";
import { createAdmin } from "../../../services/authService";

const AddAdminModal = ({ showAddAdmin, setShowAddAdmin }) => {
  const [adminForm, setAdminForm] = useState({
    fullName: "",
    email: "",
    password: ""
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const handleChange = (e) => {
    setAdminForm({ ...adminForm, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setShowAddAdmin(false);
  };
  const queryClient = useQueryClient();
  const { mutate } = useMutation(createAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-admins");
      handleClose();
    }
  });

  const handleSubmitAdmin = () => {
    const errorsTemp = {};
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!adminForm.fullName) {
      errorsTemp.fullName = "*This field is required";
    }

    if (!pattern.test(adminForm.email)) {
      errorsTemp.email = "*Please Enter a Valid Email Address";
    }
    if (adminForm.password.length < 6) {
      errorsTemp.password = "*Password must be at least 6 characters";
    }

    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      mutate(adminForm);
    }
  };
  const clearErrors = (e) => {
    setErrors({
      ...errors,
      [e.target.name]: ""
    });
  };
  const cancelAddAdmin = () => {
    setAdminForm({
      fullName: "",
      email: "",
      password: ""
    });
    handleClose();
  };
  return (
    <Modal open={showAddAdmin} onClose={handleClose}>
      <Grid
        container
        item
        xs={7}
        md={5}
        direction="column"
        className="add-user-modal"
      >
        <h1 className="modal-title">Add Admin</h1>
        <div className="modal-content">
          <TextField
            name="fullName"
            id="outlined-basic"
            label="Full Name"
            variant="outlined"
            value={adminForm.fullName}
            onChange={handleChange}
            style={{ width: "100%" }}
            error={errors.fullName ? true : false}
            onFocus={clearErrors}
          />
          {errors.fullName && (
            <p className="add-joke-error-msg">{errors.fullName}</p>
          )}
          <TextField
            name="email"
            id="outlined-basic"
            label="Email"
            variant="outlined"
            value={adminForm.email}
            onChange={handleChange}
            style={{ width: "100%" }}
            error={errors.email ? true : false}
            onFocus={clearErrors}
          />
          <FormControl
            sx={{ m: 1, margin: 0, width: "100%" }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              name="password"
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={adminForm.password}
              onChange={handleChange}
              error={errors.password ? true : false}
              onFocus={clearErrors}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={(e) => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </div>
        <div className="modal-buttons">
          <Button
            variant="outlined"
            className="add-button"
            onClick={handleSubmitAdmin}
          >
            Add
          </Button>
          <Button
            variant="outlined"
            className="cancel-button"
            onClick={cancelAddAdmin}
          >
            Cancel
          </Button>
        </div>
      </Grid>
    </Modal>
  );
};

export default AddAdminModal;
