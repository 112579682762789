import React, { useState } from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { IconButton, TextField, Button } from "@mui/material";

const FilterCategory = ({ showFilter, setShowFilter }) => {
  return (
    <div className="filter-wrapper">
      <div className="filter-backdrop" onClick={() => setShowFilter(false)} />
      <div className={showFilter ? "filter-content active" : "filter-content"}>
        <div className="filter-content-header">
          <IconButton onClick={() => setShowFilter(false)}>
            <ArrowCircleLeftIcon
              color="primary"
              style={{ width: 34, height: 34 }}
            />
          </IconButton>
          <h1>Filters</h1>
        </div>
        <div className="filter-category-inputs">
          <TextField
            id="outlined-basic"
            label="Id"
            variant="outlined"
            style={{ width: "100%" }}
          />
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            style={{ width: "100%" }}
          />
          <TextField
            id="outlined-basic"
            label="Created At"
            variant="outlined"
            style={{ width: "100%" }}
          />
          <TextField
            id="outlined-basic"
            label="Modified At"
            variant="outlined"
            style={{ width: "100%" }}
          />
        </div>
        <div className="filter-buttons-container">
          <Button variant="outlined" className="filter-button">
            Apply changes
          </Button>
          <Button
            variant="outlined"
            className="reset-button"
            onClick={() => setShowFilter(false)}
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterCategory;
